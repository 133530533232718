import styled from 'styled-components'
import { StackedBarChart } from '../../components/charts/StackedBarChart'
import { BodyLarge, Title2, Title6 } from '../../components/styles/Typography'
import { theme } from '../../theme/theme'
import { MultiLineChart } from '../../components/charts/MultiLineChart'
import { isMobile, isTablet } from '../../utils/devices'
import { useWindowWidth } from '../../hooks/useWindowWidth'
import { BarChartComponent } from '../../components/charts/BarChartComponent'
import {
  BarChartComponentCategories,
  BarChartComponentData,
  chartData,
  chartData1,
  chartData2,
  multiLineChartData,
  multiLineChartLines,
} from '../../components/charts/mockData'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'

export const Data = () => {
  const width = useWindowWidth()
  const isMobileDevice = isTablet(width) || isMobile(width)

  return (
    <ComponentLayout>
      <StyledTitle2>Statistik</StyledTitle2>
      <BodyLarge> Analysera deltagande, aktivering och mående hos era anställda.</BodyLarge>
      <ChartWrapper>
        <Divider />
        <ChartTitle>Medarbetare</ChartTitle>
        <StackedBarChart data={chartData} title="Aktivering av konto" />
      </ChartWrapper>
      <ChartWrapper>
        <Divider />
        <ChartTitle>Utbildning</ChartTitle>
        <StackedBarChart data={chartData1} title="Deltagande i utbildning" />
      </ChartWrapper>
      <ChartWrapper>
        <Divider />
        <ChartTitle>Screeningtjänst för kvinnohälsa</ChartTitle>
        <StackedBarChart data={chartData2} title="Deltagande i screeningtjänst för kvinnohälsa" />
        <Spacer />
        <MultiLineChart
          data={multiLineChartData}
          lines={multiLineChartLines}
          isMobileDevice={isMobileDevice}
          title="Skattat mående före & efter screening"
          label="Antal deltagare"
        />
        <Spacer />
        <BarChartComponent
          data={BarChartComponentData}
          categories={BarChartComponentCategories}
          isMobileDevice={isMobileDevice}
          title="NPS: Skulle du rekommendera den här tjänsten för andra?"
          minLabel="Inte alls"
          maxLabel="Högst troligt"
        />
      </ChartWrapper>
      <Spacer />
    </ComponentLayout>
  )
}

const ChartTitle = styled(Title6)`
  margin-bottom: ${theme.spacing.medium}px;
`

const ChartWrapper = styled.div`
  padding-top: ${theme.spacing.large}px;
`

const Divider = styled.hr`
  margin-bottom: ${theme.spacing.small}px;
  border: none;
  height: 1px;
  background-color: ${theme.color.beigeDark};
`

const Spacer = styled.div`
  margin-bottom: ${theme.spacing.large}px;
`
const StyledTitle2 = styled(Title2)`
  margin-bottom: ${theme.spacing.xsmall}px;
`
