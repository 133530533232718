import styled from 'styled-components'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { Title4 } from '../../components/styles/Typography'
import { ServiceCard } from '../../components/ServiceCard'
import { theme } from '../../theme/theme'
import { useGetAllServicesPages } from '../../api/contentful/contentfulQueries'
import { PreviewGuard } from '../../components/PreviewGuard'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Header } from '../../components/Header'
import * as IconComponents from '../../components/icons'
import React from 'react'
import { useSelectActiveServicesFromContentful } from '../../state/selectors'
import { Grid } from '../../components/Grid'

export const AllServices = () => {
  const { data, isLoading } = useGetAllServicesPages()
  const allServices = data?.items || []

  const activeServices = useSelectActiveServicesFromContentful(allServices)
  const inactiveServices = allServices.filter((service) => !activeServices.includes(service))

  if (isLoading) return <LoadingSpinner />
  if (!data) return <PreviewGuard />
  const haveActiveServices = activeServices?.length > 0
  const header = haveActiveServices ? (
    <Header title="Aktiverade tjänster" backgroundColor={theme.color.beigeLight} noPaddingBottom />
  ) : (
    <Header
      title="Tjänster"
      backgroundColor={theme.color.beigeLight}
      description="Här har vi samlat alla tjänster som ingår i vår hälsoportal. Som administratör har du möjlighet att enkelt aktivera de tjänster som du vill ska ingå i ert erbjudande."
      noPaddingBottom
    />
  )
  return (
    <>
      {header}
      {haveActiveServices && (
        <ComponentLayout>
          <Grid $columns={2}>
            {activeServices.map(({ fields: { title, shortDescription, slug, iconType }, sys: { id } }, index) =>
              !id || !title || !slug ? (
                <PreviewGuard key={index} />
              ) : (
                <ServiceCard
                  key={id}
                  title={title!}
                  description={shortDescription || ''}
                  linkTo={`/admin/tjanster/${slug}`}
                  isActive
                  icon={
                    iconType &&
                    React.createElement(IconComponents[iconType as keyof typeof IconComponents], {
                      size: 22,
                      color: theme.color.plumMid,
                    })
                  }
                />
              )
            )}
          </Grid>
        </ComponentLayout>
      )}

      {inactiveServices.length > 0 && (
        <ComponentLayout
          backgroundColor={haveActiveServices ? theme.color.beigeDark : theme.color.beigeLight}
          paddingBottom={90}
          noMaxWidth
        >
          <StyledTitle6>Lägg till fler tjänster</StyledTitle6>
          <Grid $columns={2}>
            {inactiveServices.map(({ fields: { title, shortDescription, slug, iconType }, sys: { id } }, index) =>
              !id || !title || !slug ? (
                <PreviewGuard key={index} />
              ) : (
                <ServiceCard
                  key={id}
                  title={title!}
                  description={shortDescription || ''}
                  linkTo={`/admin/tjanster/${slug}`}
                  icon={
                    iconType &&
                    React.createElement(IconComponents[iconType as keyof typeof IconComponents], {
                      size: 22,
                      color: theme.color.plumMid,
                    })
                  }
                />
              )
            )}
          </Grid>
        </ComponentLayout>
      )}
    </>
  )
}

const StyledTitle6 = styled(Title4)`
  padding-bottom: ${theme.spacing.large}px;
`
