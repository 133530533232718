import styled from 'styled-components'
import { theme } from '../theme/theme'
import { QRCode } from './icons/QRCode'
import { BodySmallMedium, BodySmall } from './styles/Typography'
import { FC } from 'react'
import { AccessCode } from '../api/ester-b2b-api/private/users/types'
import { ScreeningCodeStatus } from '@estercare/ester-shared'
import { Success } from './icons'

interface Props {
  accessCode?: AccessCode
}

export const ScreeningGuide: FC<Props> = ({ accessCode }) => {
  const code = accessCode?.code || '------'
  const status = accessCode?.status
  const steps = [
    {
      title: 'Ladda ner appen',
      description:
        'Sök på “EsterCare” i App Store eller Google Play eller skanna QR-koden för att ladda ner appen direkt:',
      content: <QRCode />,
    },
    {
      title: 'Logga in',
      description: 'Logga in i appen med ditt BankID',
    },
    {
      title: 'Aktivera din kod',
      description:
        'Klicka på "Profil" i menyn i appen och välj "Företagshälsovård". Aktivera sedan screeningen med din 6-siffriga kod:',
      content: (
        <CodeContainer>
          <CodeText>{code}</CodeText>
        </CodeContainer>
      ),
    },
  ]

  const claimedOrCompleteText =
    status === ScreeningCodeStatus.CLAIMED
      ? {
          title: 'Din kod har aktiverats',
          description: 'När du genomfört din screening kommer du få ditt resultat inom 3 vardagar.',
        }
      : {
          title: 'Du har genomfört din screening!',
          description: (
            <span>
              När du genomfört din screening kommer du få ditt resultat inom 3 vardagar.
              <br />
              Efter det bör du ha fått ett sms med länk till din rapport.
            </span>
          ),
        }

  return (
    <ScreeningStepContainer>
      {!status ||
        (accessCode.status === ScreeningCodeStatus.DISTRIBUTED && (
          <>
            {steps.map((item, index) => {
              return (
                <StepContainer key={index}>
                  <NumberContainer>
                    <Number>{index + 1}</Number>
                  </NumberContainer>
                  <TextContainer>
                    <BodySmallMedium>{item.title}</BodySmallMedium>
                    <BodySmall>{item.description}</BodySmall>
                    <ContentContainer>{item.content}</ContentContainer>
                  </TextContainer>
                </StepContainer>
              )
            })}
          </>
        ))}
      {(status === ScreeningCodeStatus.CLAIMED || status === ScreeningCodeStatus.COMPLETE) && (
        <StepContainer>
          <Success fill={theme.color.white} size={64} />
          <TextContainer className="claimedOrComplete">
            <BodySmallMedium>{claimedOrCompleteText.title}</BodySmallMedium>
            <BodySmall>{claimedOrCompleteText.description}</BodySmall>
          </TextContainer>
        </StepContainer>
      )}
    </ScreeningStepContainer>
  )
}

const ScreeningStepContainer = styled.div`
  display: flex;
  background-color: ${theme.color.plum};
  margin-top: ${theme.spacing.medium}px;
  padding: ${theme.spacing.xxlarge}px;
  border-radius: ${theme.spacing.medium}px;
  justify-content: space-around;
  @media screen and (max-width: ${theme.breakpoint.large}px) {
    flex-direction: column;
    padding: ${theme.spacing.large}px;
  }
`

const StepContainer = styled.div`
  width: 100%;
  padding: ${theme.spacing.small}px;
  color: ${theme.color.white};
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${theme.breakpoint.large}px) {
    padding: ${theme.spacing.small}px 0;
  }
`

const TextContainer = styled.div`
  width: 80%;
  padding-left: 16px;
  &.claimedOrComplete {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const ContentContainer = styled.div`
  padding-top: ${theme.spacing.medium}px;
`

const NumberContainer = styled.div`
  color: ${theme.color.plum};
  background-color: ${theme.color.white};
  border-radius: 50%;
  width: ${theme.spacing.xxlarge}px;
  height: ${theme.spacing.xxlarge}px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  text-align: center;
  padding-top: ${theme.spacing.tiny}px;
`

const Number = styled.p`
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0px;
  font-family: ${theme.font.heading};
  font-weight: 400;
`

const CodeContainer = styled.div`
  display: flex;
  background-color: ${theme.color.plumMid};
  border-radius: ${theme.spacing.medium}px;
  padding: ${theme.spacing.mediumLarge}px;
  align-items: center;
  justify-content: center;
`
const CodeText = styled.p`
  font-family: ${theme.font.heading};
  font-size: ${theme.fontSize.xlarge}px;
  letter-spacing: 10px;
  margin-right: -10px;
`
