import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { useParams } from 'react-router-dom'
import { PreviewGuard } from '../../components/PreviewGuard'
import { useGetGeneralPage } from '../../api/contentful/contentfulQueries'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import {
  IHighlightedListBlockFields,
  isICenteredTextBlock,
  isIFAQ,
  isIHighlightedListBlock,
  isImageBlock,
} from '@estercare/ester-shared'
import { Header } from '../../components/Header'
import { DynamicBlock } from '../../components/DynamicBlock'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { Wave } from '../../components/wave/Wave'
import { getBackgroundColor, hasBlockBackgroundColorProperty } from '../../contentful/utils'
import { OnClickButton } from '../../components/buttons/onclick/OnClickButton'
import { sendServiceRequest } from '../../api/mailchimp/mailchimp'
import { useState } from 'react'
import { Success } from '../../components/icons'
import { BodyLarge, Title3 } from '../../components/styles/Typography'
import { ErrorSign } from '../../components/icons/ErrorSign'
import { Spinner } from '../../components/icons/Spinner'
import { Modal } from '../../components/Modal'
import {
  useSelectActiveServices,
  useSelectFirstName,
  useSelectLastName,
  useSelectUserEmail,
  useSelectUserOrganisationId,
  useSelectUserOrganisationName,
} from '../../state/selectors'

export const Service = () => {
  const { id } = useParams()

  const activeServices = useSelectActiveServices()
  const organisationId = useSelectUserOrganisationId()
  const firstName = useSelectFirstName()
  const lastName = useSelectLastName()
  const email = useSelectUserEmail()
  const organisationName = useSelectUserOrganisationName()
  const servicePageQuery = useGetGeneralPage(id as string)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState<boolean>()
  const [buttonIcon, setButtonIcon] = useState<React.ReactNode>()
  if (servicePageQuery.isLoading) return <LoadingSpinner />
  if (!servicePageQuery.data?.items[0]?.fields || !email) return <PreviewGuard />
  if (!id) throw new Error('No slug provided')

  const { title, description, shortDescription, slug, contentBlocks, serviceIdentifier } =
    servicePageQuery.data.items[0].fields

  const isActive = activeServices.some((service) => service === serviceIdentifier)

  const waveColor = getBackgroundColor(hasBlockBackgroundColorProperty(contentBlocks?.[0]) || '')

  if (!description || !shortDescription || !slug || !title || !contentBlocks) return <PreviewGuard />

  const formData = {
    service: title,
    userInfo: { firstName: firstName || 'missing firstname', lastName: lastName || 'missing lastname', email },
    orgId: organisationId || 'missing orgId',
    orgName: organisationName || 'missing orgName',
  }

  const sendServiceMail = async () => {
    setIsLoading(true)
    setShowModal(true)
    const response = await sendServiceRequest(formData)
    if (!response) {
      setIsLoading(false)
      setIsSuccess(false)
      setButtonIcon(<ErrorSign color={theme.color.white} size={24} />)
    }
    if (response) {
      setIsLoading(false)
      setIsSuccess(true)
      setButtonIcon(<Success color={theme.color.white} size={24} />)
    }
  }
  const closeModal = () => {
    setShowModal(false)
  }
  const renderModalContent = () => {
    if (isLoading) {
      return (
        <>
          <Spinner size={100} color={theme.color.plum} />
          <TextBlock>
            <StyledBodyLarge>Vänligen vänta medan vi behandlar din förfrågan.</StyledBodyLarge>
          </TextBlock>
        </>
      )
    }

    if (isSuccess) {
      return (
        <>
          <Success />
          <TextBlock>
            <Title3>Vi har mottagit din förfrågan!</Title3>
            <BodyLarge>
              Inom 1-2 arbetsdagar återkommer med ett prisförslag. Därefter hjälper vi er att snabbt och smidigt komma
              igång med tjänsten på ert företag.
            </BodyLarge>
          </TextBlock>
        </>
      )
    }
    return (
      <>
        <ErrorSign />
        <TextBlock>
          <Title3>Något gick fel</Title3>
          <BodyLarge>Prova en gång till eller kontakta vår support.</BodyLarge>
        </TextBlock>
      </>
    )
  }
  return (
    <>
      <Header
        backButtonLink="/admin/tjanster"
        backgroundColor={theme.color.transparent}
        title={title}
        description={description}
        button={
          isActive ? (
            <HeaderButton>AKTIV</HeaderButton>
          ) : (
            <OnClickButton
              loading={isLoading}
              onClick={() => {
                sendServiceMail()
              }}
              icon={buttonIcon}
              text="Få ett prisförslag och aktivera tjänsten för dina anställda"
            />
          )
        }
        noPaddingBottom
        maxWidth={640}
      />
      <StyledWaveWrapper>
        <Wave color={waveColor} />
      </StyledWaveWrapper>

      {Array.isArray(contentBlocks) &&
        contentBlocks.map((block, index) => {
          const isListItemBlockComponent = isIHighlightedListBlock(block)
          const isFAQ = isIFAQ(block)
          const isImageBlockComponent = isImageBlock(block)
          const isListBlockComponent = isIHighlightedListBlock(block)
          const isTextBlockComponent = isICenteredTextBlock(block)

          return (
            <ComponentLayout
              backgroundColor={
                isListItemBlockComponent || isFAQ
                  ? getBackgroundColor((block.fields as IHighlightedListBlockFields).backgroundColor)
                  : isImageBlockComponent
                    ? '#393F54'
                    : 'transparent'
              }
              paddingTop={isImageBlockComponent || isListBlockComponent ? 1 : 64}
              paddingBottom={isImageBlockComponent ? 0 : 64}
              key={index}
              noMaxWidth={isListItemBlockComponent || isImageBlockComponent || isTextBlockComponent}
            >
              <DynamicBlock block={block} />
            </ComponentLayout>
          )
        })}
      {showModal && (
        <Modal onClose={closeModal}>
          <ContentContainer>{renderModalContent()}</ContentContainer>
        </Modal>
      )}
    </>
  )
}

const StyledBodyLarge = styled(BodyLarge)`
  margin-top: ${theme.spacing.large}px;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 250px;
  justify-content: center;
  width: 300px;
`
const TextBlock = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-top: ${theme.spacing.medium}px;
  p {
    padding-top: ${theme.spacing.xsmall}px;
    color: ${theme.color.grey5};
  }
`

const HeaderButton = styled.button`
  display: inline-flex;
  align-self: flex-start;
  background-color: ${theme.color.green};
  padding: 6px;
  color: ${theme.color.white};
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  z-index: 5;
  position: relative;
`
const StyledWaveWrapper = styled.div`
  margin-top: -30px;
`
