import { FC, ReactNode } from 'react'
import styled from 'styled-components'

export const BaseLayout: FC<{ children: ReactNode[] }> = ({ children }) => {
  return <BaseLayoutContainer>{children}</BaseLayoutContainer>
}

const BaseLayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
`
