import { useGetGeneralPage } from '../../api/contentful/contentfulQueries'
import { DynamicBlock } from '../../components/DynamicBlock'
import { PreviewGuard } from '../../components/PreviewGuard'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { BodyLarge, Title1 } from '../../components/styles/Typography'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { useSelectFirstName } from '../../state/selectors'

export const Home = () => {
  const firstName = useSelectFirstName()
  const homePage = useGetGeneralPage('home')
  const homeContent = homePage?.data?.items?.[0]?.fields

  if (homePage.isLoading) return <LoadingSpinner />
  if (!homeContent) return <PreviewGuard />

  const { description, contentBlocks } = homeContent

  return (
    <>
      <ComponentLayout paddingBottom={0}>
        <Title1>{firstName ? `Hej, ${firstName}!` : 'Hej!'}</Title1>
        <BodyLarge>{description}</BodyLarge>
      </ComponentLayout>
      {Array.isArray(contentBlocks) &&
        contentBlocks.map((block, index) => (
          <ComponentLayout key={index}>
            <DynamicBlock block={block} />
          </ComponentLayout>
        ))}
    </>
  )
}
