import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { Title6 } from '../styles/Typography'
import { MenuItem } from '../../utils/menus/types'
import { Dispatch, SetStateAction } from 'react'

interface Props {
  item: MenuItem
  setOpen?: Dispatch<SetStateAction<boolean>>
}
export const MenuListItem = ({ item, setOpen }: Props) => {
  return (
    <Item to={item.path} end onClick={setOpen && (() => setOpen(false))}>
      {({ isActive }) => (
        <>
          <>
            <IconContainer>
              {item.icon && <item.icon size={24} color={isActive ? theme.color.plum : theme.color.black} />}
            </IconContainer>
            <Title6>{item.name}</Title6>
          </>
        </>
      )}
    </Item>
  )
}

const IconContainer = styled.div`
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Item = styled(NavLink)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 8px;
  margin-bottom: 4px;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
  color: ${theme.color.black};
  font-size: 16;
  font-family: 'neue-montreal';
  font-weight: 500;
  letter-spacing: 0.1;
  background-color: ${theme.color.beigeLight};
  &:hover {
    background-color: ${theme.color.beigeDark};
  }
  &.active {
    background-color: ${theme.color.beigeDark};
    color: ${theme.color.plum};
  }
`
