import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { getContentfulClient } from '../../contentful/contentfulClient'
import { Entry, EntryCollection } from 'contentful'
import { AnyBlock, IArticleFields, IPodcastEpisode, IPortalGeneralPageFields } from '@estercare/ester-shared'

export const useGetGeneralPage = (slug: string): UseQueryResult<EntryCollection<IPortalGeneralPageFields>, Error> => {
  return useQuery({
    queryKey: ['portalGeneralPage', slug, 'contentful'],
    queryFn: async () => {
      return await getContentfulClient().getEntries<IPortalGeneralPageFields>({
        content_type: 'portalGeneralPage',
        'fields.slug': slug,
        include: 4,
      })
    },
  })
}

export const useGetAllServicesPages = (): UseQueryResult<EntryCollection<IPortalGeneralPageFields>, Error> => {
  return useQuery({
    queryKey: ['portalGeneralPage', 'allServices', 'contentful'],
    queryFn: async () => {
      return await getContentfulClient().getEntries<AnyBlock>({
        content_type: 'portalGeneralPage',
        'fields.pageType': 'SERVICE PAGE',
      })
    },
  })
}

export const useGetEntryById = (id: string): UseQueryResult<Entry<AnyBlock>, Error> => {
  return useQuery({
    queryKey: ['entry', id, 'contentful'],
    queryFn: async () => {
      return await getContentfulClient().getEntry<AnyBlock>(id)
    },
  })
}

export const useGetEntriesByContentType = <T>(contentType: string): UseQueryResult<EntryCollection<T>, Error> => {
  return useQuery({
    queryKey: ['entries', contentType, 'contentful'],
    queryFn: async () => {
      return await getContentfulClient().getEntries<T[]>({
        content_type: contentType,
        include: 4,
      })
    },
  })
}

export const useGetArticleBySlug = (slug: string): UseQueryResult<EntryCollection<IArticleFields>, Error> => {
  return useQuery({
    queryKey: ['article', slug, 'contentful'],
    queryFn: async () => {
      return await getContentfulClient().getEntries<IArticleFields>({
        content_type: 'article',
        'fields.slug': slug,
      })
    },
  })
}

export const useGetPodcastBySlug = (slug: string): UseQueryResult<EntryCollection<IPodcastEpisode>, Error> => {
  return useQuery({
    queryKey: ['contentfulEntry', 'podcastEpisode', slug],
    queryFn: async () => {
      const response = await getContentfulClient().getEntries<AnyBlock>({
        content_type: 'podcastEpisode',
        'fields.slug': slug,
        limit: 1,
      })

      if (response.items.length === 0) {
        throw new Error(`Entry with slug "${slug}" not found`)
      }

      return response
    },
  })
}
