import { useEffect } from 'react'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { Title1, Title5 } from '../../components/styles/Typography'
import { useSelectUserFullName } from '../../state/selectors'

interface TrainingMessage {
  type: string
  courseId: string
  data?: number
}
interface IFrameProgressMessageEvent extends MessageEvent {
  data: TrainingMessage
}

export const Courses = () => {
  const fullName = useSelectUserFullName()

  useEffect(() => {
    const handleReceiveMessage = (event: IFrameProgressMessageEvent) => {
      if (event.origin !== 'https://ester-public-assets.s3.eu-north-1.amazonaws.com') {
        // Host where the iframe content is stored on
        return
      }

      // console.log('Message received from iframe:', event.data)
    }

    window.addEventListener('message', handleReceiveMessage)

    return () => {
      window.removeEventListener('message', handleReceiveMessage)
    }
  }, [])

  return (
    <>
      <ComponentLayout>
        <Title1>Utbildningar</Title1>
      </ComponentLayout>
      <ComponentLayout>
        <Title5>{fullName}</Title5>
        <iframe
          src="https://ester-public-assets.s3.eu-north-1.amazonaws.com/training/utbildning-inom-kvinnohalsa/index.html" //This will eventually come from Contentful
          width="100%"
          height="900px"
          style={{ border: 'none' }}
          title="Training Module"
        />
      </ComponentLayout>
    </>
  )
}
