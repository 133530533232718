import { useAuth0 } from '@auth0/auth0-react'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { QueryKeys } from '../../types'
import { getActiveServices } from './services'
import { Services } from '@estercare/ester-shared'

export const useGetActiveServicesQuery = (): UseQueryResult<Services[], Error> => {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery({
    queryKey: [QueryKeys.Services, 'private'],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently()
      return await getActiveServices({ accessToken })
    },
  })
}
