import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { usePostLoginQuery } from '../../api/ester-b2b-api/private/users/userQueries'
import { useEffect } from 'react'
import { LoadingSpinner } from '../LoadingSpinner'
import { theme } from '../../theme/theme'
import { useNavigate, Outlet } from 'react-router-dom'
import { useUserStore } from '../../state/store'

const AuthenticationWrapper = () => {
  const { setUser } = useUserStore()
  const { isAuthenticated, logout, isLoading: isAuth0Loading } = useAuth0()
  const navigate = useNavigate()
  const { mutate, isPending, isSuccess } = usePostLoginQuery({
    options: {
      onError: (error) => {
        logout({
          openUrl() {
            navigate('/error', { state: { errorMessage: error.message } })
          },
        })
      },
      onSuccess: (user) => {
        setUser(user)
      },
    },
  })

  useEffect(() => {
    if (isAuthenticated && !isPending && !isAuth0Loading && !isSuccess) {
      mutate()
    }
  }, [isAuthenticated, mutate, isPending, isAuth0Loading, isSuccess])

  return <Outlet />
}

export const AuthenticationGuard = withAuthenticationRequired(AuthenticationWrapper, {
  onRedirecting: () => <LoadingSpinner size={75} color={theme.color.plum} />,
})
