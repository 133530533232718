import styled from 'styled-components'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { BodyMedium, Label, Title2, Title4 } from '../../components/styles/Typography'
import { theme } from '../../theme/theme'

export const PolicyEmployee = () => {
  return (
    <ComponentLayout>
      <Container>
        <Label>Company Name</Label>
        <SmallSpacer />
        <Title2>Policy för kvinnohälsa</Title2>
        <LargeSpacer />
        <StyledTitle4>Bakgrund & syfte</StyledTitle4>
        <StyledBodyMedium>
          Kvinnor möter olika hälsomässiga utmaningar under olika faser av sina liv, från reproduktiv hälsa till
          klimakteriet. Besvär och sjukdomar kopplade till deras gynekologiska och hormonella hälsa existerar utan
          motsvarande för män. Kvinnohälsa måste tas på allvar och uppmärksammas på arbetsplatsen för att säkerställa
          att kvinnor ges samma förutsättningar att prestera som män.
        </StyledBodyMedium>
        <StyledBodyMedium>
          Undersökningar visar att kvinnors hälsa ofta förbises eller marginaliseras, både inom hälso- och
          sjukvårdssystemet samt på arbetsplatser. Det är därför viktigt att arbetsgivare aktivt tar ansvar för att
          adressera dessa behov och skapa en miljö där kvinnor känner sig hörda och stöttade i sin hälsa.
        </StyledBodyMedium>
        <StyledBodyMedium>
          Genom att erkänna att könsspecifika hälsoproblem existerar tar vårt företag ett steg mot att främja en
          jämställd och hälsosam arbetsplats. Att införliva en policy för kvinnohälsa visar vårt engagemang för att
          främja jämlikhet.
        </StyledBodyMedium>
        <StyledBodyMedium>
          Med implementationen av denna policy kommer vi att kunna erbjuda relevant information, resurser och stöd för
          våra kvinnliga anställda. Detta inkluderar tillgång till förebyggande vård, stöd för reproduktiv hälsa,
          möjligheter till flexibla arbetstider för att hantera hälsofrågor och skapandet av en kultur som uppmuntrar
          öppenhet och stöd kring kvinnohälsa.
        </StyledBodyMedium>
        <StyledBodyMedium>
          Genom att aktivt stödja kvinnors hälsa investerar vi inte bara i våra anställdas välbefinnande, utan även i
          vår organisations långsiktiga framgång och hållbarhet.
        </StyledBodyMedium>
        <StyledBodyMedium>
          Policyn för kvinnohälsa syftar till att främja lika förutsättningar för alla anställda, oavsett kön, att
          prestera och känna välbefinnande på arbetsplatsen. Policyn är utformad för att skapa en inkluderande
          arbetsmiljö där varje individ behandlas med respekt och ges samma möjligheter att utvecklas och bidra till
          företagets framgång.
        </StyledBodyMedium>
        <StyledBodyMedium>
          Denna policy är tillämplig på alla kvinnliga anställda inom bolaget, oberoende av deras roll, geografiska
          placering eller anställningsstatus.
        </StyledBodyMedium>
        <LargeSpacer />
        <StyledTitle4>Åtgärder</StyledTitle4>
        <StyledBodyMedium>
          Åtgärder innefattar initiativ och resurser som syftar till att förebygga hälsoproblem eller minska risken för
          deras uppkomst bland våra kvinnliga anställda. Detta inkluderar att erbjuda stöd och utbildning för att öka
          medvetenheten och bryta tabun samt tillhandahålla tillgång till vård när det behövs.
        </StyledBodyMedium>
        <InlineHeader>Utbildning & medvetenhet</InlineHeader>
        <StyledBodyMedium>
          <ul>
            <li>
              Vi erbjuder alla anställda möjligheten att ta del av en digital utbildning om kvinnohälsa för att
              säkerställa att alla får vetenskaplig information om vad sjukdomar och besvär som till exempel PMS,
              klimakteriet, endometrios & PCOS innebär och hur de kan behandlas.
            </li>
            <li>
              Vi säkerställer även tillgång till vetenskaplig information om kvinnokroppen genom artiklar och
              poddavsnitt i vår hälsoportal för våra kvinnliga anställda.
            </li>
            <li>
              Vi erbjuder alla chefer en digital utbildning om kvinnohälsa samt hur olika problem kan te sig samt
              påverka individen och dess möjlighet att utföra arbetsuppgifter på ett fullgott sätt.
            </li>
            <li>
              Vi arbetar aktivt för att minska stigmat och tabun kopplat till menstruation, klimakteriet och andra ämnen
              kring kvinnohälsa genom öppen kommunikation och utbildningsinitiativ.
            </li>
            <li>
              Varje chef ansvarar för att sprida information om och genomförandet kring utbildningar för att säkerställa
              att vi kontinuerligt förbättrar och främjar arbetet.
            </li>
          </ul>
        </StyledBodyMedium>
        <InlineHeader>Preventiva & hälsofrämjande insatser</InlineHeader>
        <StyledBodyMedium>
          <ul>
            <li>
              Erbjuda ett årligt screeningprogram för kvinnohälsa för att identifiera och fånga upp symptom samt besvär
              som kan behandlas men som obehandlat orsakar onödigt lidande och sänkt livskvalitet.
            </li>
            <li>
              Erbjuda ett friskvårdsberättigat behandlingsprogram som inkluderar kost & träningsråd, särskilt utformade
              för kvinnors hälsa under klimakteriet för att göra hållbara livsstilsförändringar som hjälper genom denna
              period i livet.
            </li>
            <li>Tester med blodprov.</li>
          </ul>
        </StyledBodyMedium>
        <InlineHeader>Tillgång till vård & stöd</InlineHeader>
        <StyledBodyMedium>
          <ul>
            <li>
              Erbjuda tillgång till hälsovårdstjänster såsom läkarbesök och konsultationer med specialistläkare för
              kvinnor som lider av till exempel klimakteriet, PMS eller menstruationsbesvär på ett sådant sätt att det
              går ut över arbetsprestation och välmående.
            </li>
            <li>
              Sträva efter att tillhandahålla förmånliga hälsovårdsförsäkringar som täcker behandlingar (och läkemedel)
              relaterade till dessa hälsobesvär.
            </li>
          </ul>
        </StyledBodyMedium>
        <InlineHeader>Arbetsmiljö</InlineHeader>
        <StyledBodyMedium>
          <ul>
            <li>
              Erbjuda gratis mensskydd på samtliga toaletter på arbetsplatsen eller att ta med om arbetet utförs utanför
              kontoret.
            </li>
            <li>
              Säkerställa att alla kvinnliga anställda har tillgång till toaletter med rinnande vatten under
              arbetsdagen.
            </li>
            <li>
              Erbjuda flexibla arbetstidsarrangemang, möjlighet till hemarbete och kortare pauser för att hantera besvär
              som till exempel menstruationssmärta, plötsliga svettningar, hjärndimma, koncentrationssvårigheter eller
              minskad energi. Samt för att underlätta läkarbesök kopplade till dessa besvär.
            </li>
          </ul>
        </StyledBodyMedium>
        <LargeSpacer />
        <StyledTitle4>Grundläggande principer</StyledTitle4>
        <StyledBodyMedium>
          <Emphasis>Respekt och Värdighet: </Emphasis>Vi strävar efter att behandla alla medarbetare med respekt och
          värdighet oavsett ålder eller kön. Vi främjar en inkluderande arbetsmiljö där jämställdhet hyllas,
          hälsotillstånd inte förminskas eller förlöjligas och där alla har rätt till lika möjligheter.
        </StyledBodyMedium>
        <StyledBodyMedium>
          <Emphasis>Förebyggande av Diskriminering och Förlöjligande: </Emphasis>Vi förväntar oss att alla anställda
          visar respekt och tolerans gentemot varandra och agerar kraftfullt om de observerar diskriminerande eller
          förlöjligande beteende. Vi tar sådana situationer på allvar och utreder alla anklagelser för att säkerställa
          att lämpliga åtgärder vidtas.
        </StyledBodyMedium>
        <StyledBodyMedium>
          <Emphasis>Inkluderande arbetsplats: </Emphasis>Vi strävar efter att vara en inkluderande arbetsplats där alla
          anställda behandlas lika och ges samma förutsättningar. Vi arbetar aktivt med att främja en kultur av öppenhet
          och förståelse där alla anställda känner sig bekväma med att prata om sådant som påverkar arbetsprestationen.
        </StyledBodyMedium>
        <LargeSpacer />
        <StyledTitle4>Ansvar</StyledTitle4>
        <StyledBodyMedium>
          Det är viktigt att tydligt definiera och fördela ansvar för implementeringen och efterlevnaden av policyn för
          kvinnohälsa på vår arbetsplats. Nedan beskrivs vilka som är ansvariga för olika delar av policyn:
        </StyledBodyMedium>
        <StyledBodyMedium>
          <ul>
            <li>
              <Emphasis>Ledningsgruppen: </Emphasis>Ansvarar för att fastställa policyns övergripande mål och riktlinjer
              samt för att tillhandahålla nödvändiga resurser för dess genomförande.
            </li>
            <li>
              <Emphasis>HR-avdelningen: </Emphasis>Har huvudansvaret för att administrera och övervaka implementeringen
              av policyn. Detta inkluderar att se till att policyn kommuniceras till alla anställda, att genomföra
              utbildning och utvärderingar samt att hantera eventuella klagomål eller överträdelser av policyn.
            </li>
            <li>
              <Emphasis>Chefer och ledare: </Emphasis>Förväntas stödja och främja policyn bland sina teammedlemmar genom
              att agera som föredömen och förespråkare för jämställdhet och kvinnors hälsa. De är även ansvariga för att
              uppmuntra öppen kommunikation och rapportering av eventuella problem relaterade till policyn.
            </li>
            <li>
              <Emphasis>Alla anställda: </Emphasis>Förväntas följa policyn och aktivt bidra till att skapa en
              inkluderande och hälsosam arbetsmiljö genom att behandla varandra med respekt och agera i enlighet med
              policyns principer och riktlinjer.
            </li>
          </ul>
        </StyledBodyMedium>
      </Container>
    </ComponentLayout>
  )
}

const Container = styled.div`
  padding: 64px;
  max-width: 770px;
  margin: 0 auto;
  margin-top: ${theme.spacing.mediumLarge}px;
  margin-bottom: ${theme.spacing.xxlarge}px;

  border: 1px solid ${theme.color.beigeDark};
  border-radius: ${theme.spacing.medium}px;
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 3px 9px 0px rgba(0, 0, 0, 0.05);
  background: ${theme.color.white};

  @media screen and (max-width: ${theme.breakpoint.small}px) {
    background: none;
    padding: 0;
    border: none;
    box-shadow: none;
  }
`

const StyledTitle4 = styled(Title4)`
  margin-bottom: ${theme.spacing.xsmall}px;
`
const SmallSpacer = styled.div`
  height: ${theme.spacing.medium}px;
`
const LargeSpacer = styled.div`
  height: ${theme.spacing.large}px;
`
const StyledBodyMedium = styled(BodyMedium)`
  margin-bottom: ${theme.spacing.xsmall}px;
`
const Emphasis = styled.span`
  font-weight: 500;
`
const InlineHeader = styled(StyledBodyMedium)`
  font-weight: 500;
  margin-top: ${theme.spacing.medium}px;
`
