import { isIFAQ } from '@estercare/ester-shared'
import styled from 'styled-components'
import { useGetGeneralPage } from '../../api/contentful/contentfulQueries'
import { FAQ } from '../../components/Faq'
import { Header } from '../../components/Header'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { PreviewGuard } from '../../components/PreviewGuard'
import { ScreeningGuide } from '../../components/ScreeningGuide'
import { Title6 } from '../../components/styles/Typography'
import { theme } from '../../theme/theme'
import { useGetUserAccessCodeQuery } from '../../api/ester-b2b-api/private/users/userQueries'
import { useState } from 'react'
import { AccessCode } from '../../api/ester-b2b-api/private/users/types'
import { useSelectUserId } from '../../state/selectors'

export const Screening = () => {
  const screeningPage = useGetGeneralPage('screeningtjaenst-foer-kvinnohaelsa')
  const screeningPageContent = screeningPage?.data?.items?.[0]?.fields
  const userId = useSelectUserId()
  const [accessCode, setAccessCode] = useState<AccessCode | undefined>()

  const { data, isFetching } = useGetUserAccessCodeQuery({ id: userId })

  !accessCode && !isFetching && data?.code && setAccessCode({ code: data?.code, status: data?.status })

  if (!screeningPageContent) return <PreviewGuard />

  const { title, description, contentBlocks } = screeningPageContent

  if (!title) return <PreviewGuard />

  return (
    <>
      <Header
        title={title}
        description={description}
        backButtonLink="/portal/halsotjanster"
        backgroundColor={theme.color.beigeLight}
      />
      <ComponentLayout paddingTop={0}>
        <StyledTitle6>Hur genomför jag screeningen?</StyledTitle6>
        <ScreeningGuide accessCode={accessCode} />
      </ComponentLayout>
      <ComponentLayout backgroundColor={theme.color.beigeLight}>
        {contentBlocks &&
          contentBlocks.map((block, index) => isIFAQ(block) && <FAQ showSupportLink key={index} {...block.fields} />)}
      </ComponentLayout>
    </>
  )
}

const StyledTitle6 = styled(Title6)`
  margin-bottom: ${theme.spacing.xsmall}px;
`
