import styled from 'styled-components'
import { Label } from '../styles/Typography'
import { theme } from '../../theme/theme'
import { ChartData } from './type'

export type LegendData = (Omit<ChartData, 'value'> & { value?: number })[]

export type LegendProps = {
  data: LegendData
  type?: 'dots' | 'lines'
  noLabelFlex?: boolean
}

export const ChartLegend = ({ data, type = 'dots', noLabelFlex }: LegendProps) => {
  return (
    <LegendContainer $noLabelFlex={noLabelFlex} $type={type}>
      {data.map(({ name, color, value }) => (
        <LegendFlex key={name}>
          {type === 'dots' && <LegendDot $color={color} />}
          {type === 'lines' && <LegendLine $color={color} />}
          <Label>{name}</Label>
          {value && <Label>({value})</Label>}
        </LegendFlex>
      ))}
    </LegendContainer>
  )
}

const LegendContainer = styled.div<{ $type: 'dots' | 'lines'; $noLabelFlex?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  gap: 20px;
  text-transform: capitalize;
  @media screen and (max-width: ${theme.breakpoint.xlarge}px) {
    flex-direction: ${({ $noLabelFlex }) => ($noLabelFlex ? 'row' : 'column')};
    align-items: flex-start;
    gap: ${theme.spacing.medium}px;
    margin-top: ${theme.spacing.medium}px;

    ${({ $type }) =>
      $type === 'lines' &&
      `
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: ${theme.spacing.small}px ${theme.spacing.medium}px;
    `}
  }
`

const LegendDot = styled.span<{ $color?: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color || theme.color.black};
`

const LegendLine = styled.span<{ $color?: string }>`
  width: 32px;
  height: 4px;
  border-radius: 2px;
  background-color: ${({ $color }) => $color || theme.color.black};
`

const LegendFlex = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.tiny}px;
`
