import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { theme } from '../theme/theme'

interface Props {
  children?: ReactNode | ReactNode[]
  backgroundColor?: string
  padding?: number
  width?: number
  height?: number
  gridRow?: string
  gridColumn?: string
  direction?: 'row' | 'column'
}

export const BlockItem: FC<Props> = ({
  children,
  backgroundColor,
  padding,
  width,
  height,
  gridRow,
  gridColumn,
  direction,
}) => {
  return (
    <BlockItemContainer
      $backgroundColor={backgroundColor}
      $padding={padding}
      $width={width}
      $height={height}
      $gridRow={gridRow}
      $gridColumn={gridColumn}
      $direction={direction}
    >
      {children}
    </BlockItemContainer>
  )
}

export const BlockItemContainer = styled.div<{
  $backgroundColor?: string
  $height?: 'auto' | number
  $width?: 'auto' | number
  $padding?: number
  $gridColumn?: string
  $gridRow?: string
  $direction?: 'row' | 'column'
}>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction || 'column'};
  background-color: ${({ $backgroundColor }) => $backgroundColor || theme.color.white};
  border-radius: 16px;
  height: ${({ $height }) => ($height ? `${$height}px` : 'auto')};
  width: ${({ $width }) => ($width ? `${$width}px` : 'auto')};
  padding: ${({ $padding }) => ($padding ? `${$padding}px` : 0)};
  grid-column: ${({ $gridColumn }) => $gridColumn || 0};
  grid-row: ${({ $gridRow }) => $gridRow || 0};
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 3px 9px 0px rgba(0, 0, 0, 0.05);
`
