import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Cell, Label, CartesianGrid } from 'recharts'
import { ChartContainer, ChartHeader, axisStyle } from './shared'
import { BodySmallMedium } from '../styles/Typography'
import { ChartLegend, LegendData } from './Legend'
import { theme } from '../../theme/theme'
import { ChartData } from './type'

type Props = {
  isMobileDevice: boolean
  title: string
  data: ChartData[]
  categories: LegendData
  minLabel: string
  maxLabel: string
}

export const BarChartComponent = ({ isMobileDevice, title, data, categories, minLabel, maxLabel }: Props) => {
  return (
    <ChartContainer>
      <ChartHeader>
        <BodySmallMedium>{title}</BodySmallMedium>
        <ChartLegend data={categories} noLabelFlex />
      </ChartHeader>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid horizontal vertical={false} stroke={theme.color.grey2} />
          <XAxis dataKey="name" tickLine={false} axisLine={false} tick={axisStyle} height={50}>
            <Label
              value={minLabel}
              position={{
                y: 45,
                x: isMobileDevice ? 25 : 55,
              }}
              fontSize={theme.fontSize.xxsmall}
              fontFamily={theme.font.body}
              fill={theme.color.grey4}
            />
            <Label
              value={maxLabel}
              position="insideBottomRight"
              fontSize={theme.fontSize.xxsmall}
              fontFamily={theme.font.body}
              fill={theme.color.grey4}
            />
          </XAxis>
          <YAxis
            domain={[0, 'dataMax']}
            ticks={[0, 10, 20, 30, 40]}
            axisLine={false}
            tickLine={false}
            tick={axisStyle}
            width={isMobileDevice ? 20 : undefined}
          >
            {!isMobileDevice && (
              <Label
                fontWeight={500}
                fontSize={10.5}
                fontFamily="neue-montreal"
                dx={-20}
                value="Antal deltagare"
                position="center"
                angle={-90}
              />
            )}
          </YAxis>

          <Bar dataKey="value" name="category" fill="#8884d8">
            {data.map(({ color }, index) => (
              <Cell radius={4} key={`cell-${index}`} fill={color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  )
}
