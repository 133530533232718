import { env } from './utils/environments/environmentVariables'

export const appConfig = {
  serverRootUrl: env.withDefault({ name: 'VITE_SERVER_ROOT_URL', defaultValue: 'https://b2b-api-test.ester.care' }),
  environment: env.withDefault({ name: 'VITE_NODE_ENV', defaultValue: 'development' }),
  auth0: {
    domain: env.requiredUnlessTesting('VITE_AUTH0_DOMAIN') || '',
    clientId: env.requiredUnlessTesting('VITE_AUTH0_CLIENT_ID') || '',
    audience: env.requiredUnlessTesting('VITE_AUTH0_AUDIENCE'),
  },
  sentry: {
    sentryDsn: env.requiredUnlessTesting('VITE_SENTRY_DSN_B2B_WEB'),
  },
  mailchimp: {
    apiKey: env.optional('VITE_MAILCHIMP_API_KEY'),
  },
}
