import styled from 'styled-components'
import { Grid } from '../../components/Grid'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { BodyLarge, Title2, Title6 } from '../../components/styles/Typography'
import { theme } from '../../theme/theme'
import { TrainingCard } from '../../components/TrainingCard'

// Will be replaced with a combination of Contentful data + data from our own backend
const mockData = [
  {
    id: 1,
    title: 'För alla anställda',
    description: 'Consectetur adipiscing elit, labore et dolore magna aliqua.',
    duration: 8,
    progress: 20,
    imageUrl: '/images/stock1.png',
    trainingUrl: '/portal/utbildning/example',
  },
  {
    id: 2,
    title: 'För chefer',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    duration: 16,
    progress: 0,
    imageUrl: '/images/stock2.png',
    trainingUrl: '/portal/utbildning/example',
  },
]

export const Training = () => {
  return (
    <ComponentLayout>
      <StyledTitle2>Utbildning</StyledTitle2>
      <StyledBodyLarge>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam.
      </StyledBodyLarge>
      <StyledTitle6>Kurser (0/2 avklarade)</StyledTitle6>
      <Grid $columns={2}>
        {mockData.map((training, index) => (
          <TrainingCard
            key={index}
            url={training.trainingUrl}
            imageUrl={training.imageUrl}
            title={training.title}
            description={training.description}
            duration={training.duration}
            progress={training.progress}
          />
        ))}
      </Grid>
    </ComponentLayout>
  )
}

const StyledTitle2 = styled(Title2)`
  margin-bottom: ${theme.spacing.xsmall}px;
`
const StyledBodyLarge = styled(BodyLarge)`
  margin-bottom: ${theme.spacing.mediumLarge}px;
  max-width: 640px;
`
const StyledTitle6 = styled(Title6)`
  color: ${theme.color.black};
  margin-bottom: ${theme.spacing.medium}px;
`
