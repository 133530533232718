import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Label, CartesianGrid } from 'recharts'
import { BodySmallMedium } from '../styles/Typography'
import { ChartLegend } from './Legend'
import { ChartContainer, ChartHeader, axisStyle } from './shared'
import { theme } from '../../theme/theme'
import { MultiLineChartData, MultiLineChartLine } from './type'

type Props = {
  isMobileDevice: boolean
  data: MultiLineChartData[]
  lines: MultiLineChartLine[]
  title: string
  label: string
}

export const MultiLineChart = ({ isMobileDevice, data, lines, title, label }: Props) => (
  <ChartContainer>
    <ChartHeader>
      <BodySmallMedium>{title}</BodySmallMedium>
      <ChartLegend type="lines" data={lines.map(({ dataKey, color }) => ({ name: dataKey, color }))} />
    </ChartHeader>
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={data}
        margin={{
          right: isMobileDevice ? 5 : 30,
          left: isMobileDevice ? -15 : 0,
        }}
      >
        <CartesianGrid horizontal vertical={false} stroke={theme.color.grey2} />
        <XAxis
          dataKey={isMobileDevice ? 'nameMobile' : 'name'}
          padding={{ left: isMobileDevice ? 20 : 100, right: isMobileDevice ? 20 : 100 }}
          axisLine={false}
          tickLine={false}
          tick={axisStyle}
          interval={0}
          height={50}
        />
        <YAxis
          domain={[0, 'dataMax']}
          ticks={[0, 10, 20, 30, 40]}
          axisLine={false}
          tickLine={false}
          tick={axisStyle}
          width={isMobileDevice ? 40 : undefined}
        >
          {!isMobileDevice && (
            <Label
              fontWeight={500}
              fontSize={theme.fontSize.xxsmall}
              fontFamily={theme.font.body}
              dx={-20}
              value={label}
              position="center"
              angle={-90}
            />
          )}
        </YAxis>
        {lines.map(({ dataKey, color }) => (
          <Line
            key={dataKey}
            type="monotone"
            dataKey={dataKey}
            stroke={color}
            strokeWidth={2}
            dot={{ r: 3, fill: color }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  </ChartContainer>
)
