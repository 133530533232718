export type ChartData = {
  name: string | number
  value: number
  color: string
}

export type MultiLineChartLine = {
  dataKey: string
  color: string
}

export type MultiLineChartData = {
  name: string
  nameMobile: string
} & {
  [K in MultiLineChartDataKey]: number
}

export type MultiLineChartProps = {
  data: MultiLineChartData[]
  lines: MultiLineChartLine[]
}

export enum MultiLineChartDataKey {
  MYCKET_BRA = 'MYCKET BRA',
  BRA = 'BRA',
  DALIGT = 'DÅLIGT',
  VALDIGT_DALIGT = 'VÄLDIGT DÅLIGT',
}
