import { appConfig } from '../../../../config'

export const getActiveServices = async ({ accessToken }: { accessToken: string }) => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/services/activated`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return res.json()
}
