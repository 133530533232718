import { FC, useState } from 'react'
import styled from 'styled-components'
import { OnClickButton } from './buttons/onclick/OnClickButton'
import { PreviewGuard } from './PreviewGuard'
import { IArticleFields } from '@estercare/ester-shared'
import { Title6 } from './styles/Typography'
import { BlockItem } from './BlockItem'
import { CollectionCard } from './CollectionCard'
import { theme } from '../theme/theme'
import { Grid } from './Grid'
import { splitArrayInto2and4Chunks } from '../utils/arrays'
import { TextLink } from './TextLink'
import { useWindowWidth } from '../hooks/useWindowWidth'
import { isMobile, isTablet } from '../utils/devices'

interface Props {
  articles: IArticleFields[]
  title?: string
  backgroundColor?: string
  showAsArchive?: boolean
}

export const ArticleList: FC<Props> = ({ articles, title, showAsArchive = false }) => {
  const width = useWindowWidth()
  const isMobileDevice = isTablet(width) || isMobile(width)

  const loadMoreArticlesCount = 18
  const startingArticlesShownCount = 6

  const [articleCount, setArticleCount] = useState(startingArticlesShownCount)
  const shownArticles = articles.slice(0, articleCount)

  const articlesInChunks = splitArrayInto2and4Chunks(shownArticles)
  const numberOfChunks = articleCount / 3
  const moreArticlesToShow = articleCount <= shownArticles.length

  const articleCollections = articlesInChunks.slice(0, numberOfChunks).map((chunk) => {
    return chunk.map((article, index) => {
      const { slug, title, abstract, primaryImage } = article
      const label = 'Artikel'
      if (!slug || !title || !abstract || !primaryImage) return <PreviewGuard key={index} />
      const isHorizontal = chunk.length === 2 && showAsArchive
      const height = isHorizontal ? 215 : 368

      return (
        <BlockItem key={index} height={height} gridColumn={isHorizontal && !isMobileDevice ? 'span 2' : 'span 1'}>
          <CollectionCard
            horizontal={isHorizontal}
            slug={`/portal/kunskapsbank/artiklar/${slug}`}
            title={title}
            abstract={abstract}
            label={label}
            image={primaryImage}
          />
        </BlockItem>
      )
    })
  })

  return (
    <>
      {title && <Title6>{title}</Title6>}
      <StyledGrid $columns={isMobileDevice ? 2 : 4}>{articleCollections}</StyledGrid>

      {showAsArchive && moreArticlesToShow ? (
        <ButtonContainer>
          <OnClickButton
            text="Fler artiklar"
            onClick={() => setArticleCount((articleRows) => articleRows + loadMoreArticlesCount)}
            secondary
          />
        </ButtonContainer>
      ) : (
        <LinkContainer>
          <TextLink text="Till vår kunskapsbank" href="/portal/kunskapsbank" />
        </LinkContainer>
      )}
    </>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: ${theme.spacing.xxlarge}px;
`
const LinkContainer = styled.div`
  display: flex;
  margin-top: ${theme.spacing.large}px;
  justify-content: flex-end;
`
const StyledGrid = styled(Grid)`
  margin-top: ${theme.spacing.medium}px;
`
