import { Routes, Route, Outlet } from 'react-router-dom'
import { AuthenticationGuard } from './components/auth/AuthenticationGuard'
import { initSentry } from './sentry.ts'
import { Home } from './views/employee/Home'
import { ComponentLibrary } from './views/componentLibrary/Index'
import { ComponentLibraryBaseLayout } from './components/layouts/ComponentLibraryBaseLayout'
import { Typography } from './views/componentLibrary/Typography'
import { BaseLayout } from './components/layouts/BaseLayout'
import { Index } from './views/Index'
import { Sidebar } from './components/sidebar/Sidebar'
import { AdminViewGuard, EmployeeViewGuard } from './components/auth/AuthorizationGuard'
import { Colors } from './views/componentLibrary/Colors'
import { Grids } from './views/componentLibrary/Grid'
import { Buttons } from './views/componentLibrary/Buttons'
import { Icons } from './views/componentLibrary/Icons.tsx'
import { AllServices } from './views/admin/AllServices.tsx'
import { Data } from './views/admin/Data.tsx'
import { Employees } from './views/admin/Employees.tsx'
import { Invoices } from './views/admin/Invoices.tsx'
import { HealthServices } from './views/employee/HealthServices.tsx'
import { Archive } from './views/employee/Archive.tsx'
import { PolicyAdmin } from './views/admin/Policy.tsx'
import { PolicyEmployee } from './views/employee/Policy.tsx'
import { Training } from './views/employee/Training.tsx'
import { Courses } from './views/employee/Courses.tsx'
import { ContentfulBlocks } from './views/componentLibrary/ContentfulBlocks.tsx'
import { Article } from './views/employee/Article.tsx'
import { Podcast } from './views/employee/Podcast.tsx'
import { EmployeeSupport } from './views/employee/EmployeeSupport.tsx'
import { Support } from './views/admin/Support.tsx'
import { HomeAdmin } from './views/admin/Home.tsx'
import { Service } from './views/admin/Service.tsx'
import { ErrorPage } from './views/Error.tsx'
import { Login } from './views/Login.tsx'
import { Screening } from './views/employee/Screening.tsx'
import { useAuth0 } from '@auth0/auth0-react'
import { Profile } from './views/Profile.tsx'

initSentry()

export const App = () => {
  const { isAuthenticated } = useAuth0()
  return (
    <BaseLayout>
      {isAuthenticated && <Sidebar />}
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/login" element={<Login />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="*" element={<div>404 🤓</div>} />
        <Route element={<AuthenticationGuard />}>
          <Route path="profil" element={<Profile />} />
          <Route path="admin" element={<AdminViewGuard />}>
            <Route index element={<HomeAdmin />} />
            <Route path="dashboard" element={<HomeAdmin />} />
            <Route path="tjanster" element={<Outlet />}>
              <Route index element={<AllServices />} />
              <Route path="policy" element={<PolicyAdmin />} />
              <Route path=":id" element={<Service />} />
            </Route>
            <Route path="statistik" element={<Data />} />
            <Route path="medarbetare" element={<Employees />} />
            <Route path="fakturering" element={<Invoices />} />
            <Route path="support" element={<Support />} />
          </Route>
          <Route path="portal" element={<EmployeeViewGuard />}>
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="halsotjanster" element={<Outlet />}>
              <Route index element={<HealthServices />} />
              <Route path="screening" element={<Screening />} />
            </Route>
            <Route path="utbildning" element={<Outlet />}>
              <Route index element={<Training />} />
              <Route path="example" element={<Courses />} />
            </Route>
            <Route path="kunskapsbank" element={<Outlet />}>
              <Route index element={<Archive />} />
              <Route path="artiklar/:id" element={<Article />} />
              <Route path="podcasts/:id" element={<Podcast />} />
            </Route>
            <Route path="foretagspolicy" element={<PolicyEmployee />} />
            <Route path="support" element={<EmployeeSupport />} />
          </Route>
        </Route>
        <Route path="component-library" element={<ComponentLibraryBaseLayout />}>
          <Route index element={<ComponentLibrary />} />
          <Route path="colors" element={<Colors />} />
          <Route path="typography" element={<Typography />} />
          <Route path="grid" element={<Grids />} />
          <Route path="buttons" element={<Buttons />} />
          <Route path="icons" element={<Icons />} />
          <Route path="contentful-blocks" element={<ContentfulBlocks />} />
        </Route>
      </Routes>
    </BaseLayout>
  )
}
