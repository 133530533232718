import { IPodcastEpisodeFields, isDocument } from '@estercare/ester-shared'
import { FC, useState } from 'react'
import styled from 'styled-components'
import { theme } from '../theme/theme'
import { Grid } from './Grid'
import { PreviewGuard } from './PreviewGuard'
import { Title6 } from './styles/Typography'
import { BlockItem } from './BlockItem'
import { PodcastButton } from './PodcastButton'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { OnClickButton } from './buttons/onclick/OnClickButton'
import { TextLink } from './TextLink'
import { useWindowWidth } from '../hooks/useWindowWidth'
import { isTablet } from '../utils/devices'

export interface Params {
  title: string
  podcasts: IPodcastEpisodeFields[]
  backgroundColor?: string
  showAsArchive?: boolean
}

export const PodcastList: FC<Params> = ({ title, podcasts, showAsArchive = false }) => {
  const width = useWindowWidth()

  const startingPodsShownCount = showAsArchive ? 9 : 3
  const loadMorePodsCount = 18

  const [podcastIndex, setPodcastIndex] = useState<number>(startingPodsShownCount)
  const latestEpisodes = Array.isArray(podcasts)
    ? // Array.sort mutates the original array, so we need to make a copy, otherwise we'll risk breaking other components
      [...podcasts].sort((podCastA, podcastB) => (podcastB?.episodeNumber || 0) - (podCastA?.episodeNumber || 0))
    : []

  const displayedPodcast = latestEpisodes.slice(0, podcastIndex)
  const morePodcastsToShow = displayedPodcast.length < podcasts.length - 1

  return (
    <PodcastContainer>
      {title && <StyledTitle6>{title}</StyledTitle6>}
      <StyledGrid $columns={isTablet(width) ? 2 : 3}>
        {Array.isArray(displayedPodcast) &&
          displayedPodcast.map((podcast, index) => {
            const { title, episodeNumber, description, slug } = podcast
            const href = `/portal/kunskapsbank/podcasts/${slug}`
            const podSubtitle = isDocument(description) ? documentToPlainTextString(description) : 'Saknar beskrivning'
            if (!title) return <PreviewGuard key={index} />
            return (
              <BlockItem height={98} key={index}>
                <PodcastButton title={title} subtitle={podSubtitle} number={`${episodeNumber || 0}`} href={href} />
              </BlockItem>
            )
          })}
      </StyledGrid>
      {morePodcastsToShow && (
        <>
          {showAsArchive ? (
            <ButtonContainer>
              <OnClickButton
                text="Fler poddavsnitt"
                secondary
                onClick={() => setPodcastIndex((prevValue) => prevValue + loadMorePodsCount)}
              />
            </ButtonContainer>
          ) : (
            <LinkContainer>
              <TextLink text="Till vår kunskapsbank" href="/portal/kunskapsbank" />
            </LinkContainer>
          )}
        </>
      )}
    </PodcastContainer>
  )
}

const StyledGrid = styled(Grid)`
  margin-top: ${theme.spacing.medium}px;
`
const PodcastContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const LinkContainer = styled.div`
  display: flex;
  margin-top: ${theme.spacing.large}px;
  justify-content: flex-end;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: ${theme.spacing.xxlarge}px;
`
const StyledTitle6 = styled(Title6)`
  margin-bottom: ${theme.spacing.small}px;

  @media screen and (min-width: ${theme.breakpoint.medium}px) {
    margin-bottom: ${theme.spacing.xsmall}px;
  }
`
