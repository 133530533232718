import { ChartData, MultiLineChartData, MultiLineChartDataKey, MultiLineChartLine } from './type'

export const chartData: ChartData[] = [
  {
    name: 'EJ AKTIVERAT KONTO',
    value: 6,
    color: '#EF643A',
  },
  {
    name: 'AKTIVERAT KONTO',
    value: 60,
    color: '#4DBA99',
  },
]

export const chartData1: ChartData[] = [
  {
    name: 'Ej Påbörjat utbildning',
    value: 3,
    color: '#EF643A',
  },
  {
    name: 'Påbörjat utbildning',
    value: 10,
    color: '#AB91CE',
  },
  {
    name: 'Genomfört utbildning',
    value: 20,
    color: '#4DBA99',
  },
]

export const chartData2: ChartData[] = [
  {
    name: 'Ej Påbörjat Screening',
    value: 1,
    color: '#EF643A',
  },
  {
    name: 'Påbörjat screening',
    value: 5,
    color: '#AB91CE',
  },
  {
    name: 'Genomfört Screening',
    value: 10,
    color: '#4DBA99',
  },
]

export const multiLineChartData: MultiLineChartData[] = [
  {
    name: 'Före screening',
    nameMobile: 'Före',
    [MultiLineChartDataKey.MYCKET_BRA]: 15,
    [MultiLineChartDataKey.BRA]: 28,
    [MultiLineChartDataKey.DALIGT]: 22,
    [MultiLineChartDataKey.VALDIGT_DALIGT]: 9,
  },
  {
    name: 'Efter screening',
    nameMobile: 'Efter',
    [MultiLineChartDataKey.MYCKET_BRA]: 38,
    [MultiLineChartDataKey.BRA]: 32,
    [MultiLineChartDataKey.DALIGT]: 8,
    [MultiLineChartDataKey.VALDIGT_DALIGT]: 3,
  },
]

export const multiLineChartLines: MultiLineChartLine[] = [
  { dataKey: MultiLineChartDataKey.MYCKET_BRA, color: '#4DBA99' },
  { dataKey: MultiLineChartDataKey.BRA, color: '#AB91CE' },
  { dataKey: MultiLineChartDataKey.DALIGT, color: '#EF643A' },
  { dataKey: MultiLineChartDataKey.VALDIGT_DALIGT, color: '#E63946' },
]

export const BarChartComponentData: ChartData[] = [
  { name: '0', value: 1, color: '#B22222' },
  { name: '1', value: 0, color: '#B22222' },
  { name: '2', value: 0, color: '#B22222' },
  { name: '3', value: 10, color: '#B22222' },
  { name: '4', value: 6, color: '#B22222' },
  { name: '5', value: 0, color: '#9370DB' },
  { name: '6', value: 14, color: '#9370DB' },
  { name: '7', value: 6, color: '#9370DB' },
  { name: '8', value: 16, color: '#3CB371' },
  { name: '9', value: 24, color: '#3CB371' },
  { name: '10', value: 38, color: '#3CB371' },
]

export const BarChartComponentCategories = [
  {
    name: 'KRITIKER',
    color: '#B22222',
  },
  {
    name: 'PASSIVA',
    color: '#9370DB',
  },
  {
    name: 'AMBASSADÖRER',
    color: '#3CB371',
  },
]
