import { ILinkBlock, ILinkListFields } from '@estercare/ester-shared'
import { FC } from 'react'
import { PreviewGuard } from './PreviewGuard'
import { SupportCard } from './SupportCard'
import { Grid } from './Grid'

interface Props {
  linkList: ILinkListFields
}

export const SupportBlock: FC<Props> = ({ linkList }) => {
  const supportLinks = linkList.links

  const isLinkBlock = (block: unknown): block is ILinkBlock => {
    return (block as ILinkBlock)?.sys.contentType.sys.id === 'linkBlock'
  }

  if (!supportLinks) return <PreviewGuard />
  return (
    <Grid $columns={3}>
      {supportLinks.map((link, index) => {
        if (isLinkBlock(link) && link.fields.url && link.fields.label) {
          const { url, label, icon } = link.fields
          return <SupportCard key={index} url={url} title={label} icon={icon} />
        } else return null
      })}
    </Grid>
  )
}
