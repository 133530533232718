import mailchimpAPI, { MessageRecipient } from '@mailchimp/mailchimp_transactional'
import axios from 'axios'
import { appConfig } from '../../config'
import { sendErrorToSentry } from '../../utils/sentry'

const mailchimpClient = mailchimpAPI(appConfig.mailchimp.apiKey || '')

interface ServiceRequestBody {
  orgId: string
  orgName: string
  userInfo: { firstName: string; lastName: string; email: string }
  service: string
}

export const sendServiceRequest = async ({ orgId, orgName, userInfo, service }: ServiceRequestBody) => {
  const receivingEmail = appConfig.environment === 'production' ? 'hello@estercare.com' : 'developers@estercare.com'
  const messageRecipient: MessageRecipient[] = [
    {
      email: receivingEmail,
    },
  ]
  try {
    const response = await mailchimpClient.messages.send({
      message: {
        from_name: 'EsterCare tjänsteförfrågan',
        from_email: 'b2bService@ester.care',
        to: messageRecipient,
        text: `${orgName} har skickat en förfrågan för om tjänsten ${service}.\n\nAvsändare: ${userInfo.firstName} ${userInfo.lastName}\nEmail: ${userInfo.email}\nOrganisationsnr: ${orgId}\n`,
        subject: `${orgName} har skickat en förfrågan om tjänsten ${service}`,
      },
    })

    if (Array.isArray(response) && response[0]?.status !== 'sent') throw new Error(`Could not send email: ${response}`)
    if (axios.isAxiosError(response)) throw new Error(`Could not send email: ${response.status} ${response.message} `)
    return response
  } catch (error) {
    sendErrorToSentry(error)
    return null
  }
}
