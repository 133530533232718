import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { theme } from '../../theme/theme'

export const ViewLayout: FC<{ children: ReactNode | ReactNode[]; backgroundColor?: string }> = ({
  children,
  backgroundColor,
}) => {
  return <ViewLayoutContainer $backgroundColor={backgroundColor}>{children}</ViewLayoutContainer>
}

const ViewLayoutContainer = styled.div<{ $backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: 0 auto;
  overflow-y: scroll;
  overflow-x: hidden;
  background: ${({ $backgroundColor }) => $backgroundColor || theme.color.beigeLight};
  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    padding-top: ${theme.spacing.mobileHeaderHeight}px;
  }
`
