import { FC } from 'react'
import { BodyLarge } from './styles/Typography'
import { env } from '../utils/environments/environmentVariables'

export const PreviewGuard: FC = () => {
  if (env.isProd('VITE_NODE_ENV')) {
    throw new Error('Rendering component fallback')
  } else {
    // eslint-disable-next-line no-console
    console.log('Rendering component fallback')
    return <BodyLarge>Missing required attributes</BodyLarge>
  }
}
