import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 24
const DEFAULT_COLOR = theme.color.plumMid

interface ArrowProps extends IconProps {
  left?: boolean
}

export const Arrow = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR, left = false, ...rest }: ArrowProps) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d={left ? 'M1.60869 6.06665L15 6.06665' : 'M13.7935 9.05005L3.75 9.05005'}
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d={left ? 'M5.32422 11L0.999291 6.06667L5.26016 1' : 'M11.0063 12.75L14.25 9.05L11.0544 5.25'}
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
