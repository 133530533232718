import styled from 'styled-components'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { BodyLarge, Title2, Title6 } from '../../components/styles/Typography'
import { theme } from '../../theme/theme'
import { useGetGeneralPage } from '../../api/contentful/contentfulQueries'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { PreviewGuard } from '../../components/PreviewGuard'
import { isITextWithImageBlock } from '@estercare/ester-shared'
import { TextWithImageCard } from '../../components/TextWithImageCard'

export const HealthServices = () => {
  const healthServicesPage = useGetGeneralPage('halsotjanster')
  const healthServicesContent = healthServicesPage?.data?.items?.[0]?.fields

  if (healthServicesPage.isLoading) return <LoadingSpinner />
  if (!healthServicesContent) return <PreviewGuard />

  const { title, contentBlocks } = healthServicesContent

  return (
    <>
      <ComponentLayout paddingBottom={0}>
        <Title2>{title}</Title2>
      </ComponentLayout>
      <ComponentLayout>
        <StyledTitle6>Tjänster som ej aktiverats av din arbetsgivare</StyledTitle6>
        <StyledBodyLarge>
          Du kan välja att bekosta dessa själv (ofta med hjälp av ditt friskvårdsbidrag!)
        </StyledBodyLarge>
        {contentBlocks &&
          contentBlocks.map((block, index) => {
            return isITextWithImageBlock(block) && <TextWithImageCard key={index} {...block.fields} />
          })}
      </ComponentLayout>
    </>
  )
}

const StyledTitle6 = styled(Title6)`
  margin-bottom: ${theme.spacing.xsmall}px;
`

const StyledBodyLarge = styled(BodyLarge)`
  margin-bottom: ${theme.spacing.medium}px;
`
