/* eslint-disable no-console */
import styled from 'styled-components'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import {
  BodyLarge,
  BodySmall,
  BodySmallMedium,
  BodyTiny,
  Label,
  Title2,
  Title4,
} from '../../components/styles/Typography'
import { theme } from '../../theme/theme'
import { HorizontalDots } from '../../components/icons/HorizontalDots'
import { useWindowWidth } from '../../hooks/useWindowWidth'
import { isTablet, isMobile } from '../../utils/devices'
import React, { useCallback, useEffect, useState } from 'react'
import { OnClickButton } from '../../components/buttons/onclick/OnClickButton'
import { Close } from '../../components/icons/Close'
import { SearchLight } from '../../components/icons/SearchLight'
import { Polygon } from '../../components/icons/Polygon'
import { MenuDropdown } from '../../components/MenuDropdown'
import {
  useDeleteUserMutation,
  useGetUsersFromOrganisationQuery,
  useUpdateUserMutation,
} from '../../api/ester-b2b-api/private/users/userQueries'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import { Alert, AlertTypes } from '../../components/Alert'
import { sendErrorToSentry } from '../../utils/sentry'
import { LoadingState } from '../../components/DropZone'
import { DropZone } from '../../components/DropZone'
import { Modal } from '../../components/Modal'
import { usePostUsers } from '../../api/ester-b2b-api/private/users/userQueries'
import { UserDataMessage } from '@estercare/ester-shared'
import { useSelectUserId, useSelectUserOrganisationId } from '../../state/selectors'
import { UsersFromOrganisationDb } from '../../api/ester-b2b-api/private/users/types'

enum FileUploadState {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

const errorMessages: Record<UserDataMessage, string> = {
  [UserDataMessage.TOO_SHORT]: 'Filen behöver innehålla minst två rader med data.',
  [UserDataMessage.MISSING_FIELDS]: 'Filen behöver inneghålla minst tre kolumner: Email, förnamn och efternamn.',
  [UserDataMessage.MISSING_FIELD]: 'En eller flera rader saknar värden i en eller flera kolumner.',
  [UserDataMessage.INVALID_EMAIL]: 'Ogiltig e-postadress på en eller flera rader.',
  [UserDataMessage.VALID]: 'Filen är giltig.',
}

export const Employees = () => {
  const organisationId = useSelectUserOrganisationId()
  const userId = useSelectUserId()
  const [search, setSearch] = useState('')
  const [sortField, setSortField] = useState<'createdAt' | 'lastLogin'>('createdAt')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState<UsersFromOrganisationDb>()
  const [showDropZone, setShowDropZone] = useState(false)
  const [fileUploadState, setFileUploadState] = useState<LoadingState>(null)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [alertState, setAlertState] = useState<{
    message?: string
    type?: AlertTypes
  }>({
    message: undefined,
    type: undefined,
  })

  const width = useWindowWidth()
  const isMobileDevice = isMobile(width) || isTablet(width)
  const colSpan = isMobileDevice ? 1 : 1
  const nameColSpan = isMobileDevice ? 3 : 2
  const lineColSpan = isMobileDevice ? 5 : 8

  const { mutate: updateUserMutation } = useUpdateUserMutation()

  const { data: users = [], refetch: refetchGetUsersFromOrganisationQuery } = useGetUsersFromOrganisationQuery(
    organisationId || ''
  )

  const { mutate: deleteUser } = useDeleteUserMutation()

  const { mutate: postUser, isSuccess, isError, isPending, error, data } = usePostUsers()
  const noOfCreatedUsers = data?.createdUsers.length
  const noOfExistingUsers = data?.alreadyExistingUsers.length

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (alertState.message) {
      const timer = setTimeout(() => {
        setAlertState({
          message: undefined,
          type: undefined,
        })
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [alertState.message])

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }
  const handleSort = (field: 'createdAt' | 'lastLogin') => {
    if (field === sortField) {
      setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'))
    } else {
      setSortField(field)
      setSortOrder('asc')
    }
  }

  const onDeleteUser = () => {
    if (selectedEmployee) {
      deleteUser(selectedEmployee.id, {
        onSuccess: () => {
          refetchGetUsersFromOrganisationQuery()
          setAlertState({
            message: 'Användaren raderad',
            type: 'success',
          })
        },
        onError: (error) => {
          sendErrorToSentry(error)
          setAlertState({
            message: 'Det gick inte att ta bort användaren, vänligen försök igen.',
            type: 'error',
          })
        },
        onSettled: () => {
          setOpenDialog(false)
        },
      })
    }
  }

  useEffect(() => {
    isPending && setFileUploadState(FileUploadState.LOADING)
    if (isSuccess) {
      setFileUploadState(FileUploadState.SUCCESS)
      setAlertState({
        message: `${noOfCreatedUsers ? `${noOfCreatedUsers} medarbetare tillagda. ` : ''}${noOfExistingUsers ? `${noOfExistingUsers} medarbetare fanns redan i systemet.` : ''}`,
        type: 'success',
      })
    }
    if (isError) {
      setFileUploadState(FileUploadState.FAILED)
      const errorType = Object.keys(errorMessages).find((key) => error.message.includes(key))
      if (errorType) setErrorMessage(errorMessages[errorType as UserDataMessage])
    }
  }, [isSuccess, isError, isPending, error, noOfCreatedUsers, noOfExistingUsers])

  const uploadFile = useCallback(
    (files: File[]) => {
      if (files.length > 0) {
        const usersFileData = new FormData()
        files.forEach((file) => usersFileData.append('file', file))
        postUser({ usersFileData })
      }
    },
    [postUser]
  )

  const searchIcon =
    search.length > 0 ? (
      <div onClick={() => setSearch('')}>
        <Close size={24} color={theme.color.grey5} />
      </div>
    ) : (
      <div onClick={() => console.log('Search!')}>
        <SearchLight size={24} color={theme.color.grey5} />
      </div>
    )

  const renderSortIcon = (field: 'createdAt' | 'lastLogin') => {
    const isActive = sortField === field
    const rotation = isActive && sortOrder === 'desc' ? 180 : 0

    return (
      <IconWrapper $rotation={rotation}>
        <Polygon />
      </IconWrapper>
    )
  }

  const filteredEmployees = users.filter((employee) => {
    const searchLower = search.toLowerCase()
    const fullName = `${employee.firstName} ${employee.lastName}`.toLowerCase()
    const email = employee.email.toLowerCase()
    return fullName.includes(searchLower) || email.includes(searchLower)
  })

  const sortedEmployees = [...filteredEmployees].sort((a, b) => {
    const aValue = a[sortField] ? new Date(a[sortField]).getTime() : 0
    const bValue = b[sortField] ? new Date(b[sortField]).getTime() : 0

    if (sortOrder === 'asc') {
      return aValue - bValue
    } else {
      return bValue - aValue
    }
  })

  return (
    <>
      <ComponentLayout>
        <div>
          <StyledTitle2>Medarbetare ({users.length} st) </StyledTitle2>
          <BodyLarge> Hantera vilka medarbetare som ska ha tillgång till EsterCares tjänster.</BodyLarge>
        </div>

        <AlertWrapper>
          {alertState.message && <Alert message={alertState.message} type={alertState.type || 'success'} />}
        </AlertWrapper>
        <InputContainer $isMobile={isMobileDevice}>
          <SearchContainer>
            <SearchInput type="text" name="searchBar" placeholder="Sök" value={search} onChange={handleSearch} />
            {searchIcon}
          </SearchContainer>
          <OnClickButton text="Lägg till medarbetare" onClick={() => setShowDropZone(true)} />
        </InputContainer>

        <StyledTable>
          <StyledTHead $isMobile={isMobileDevice}>
            <tr>
              <th id="name" colSpan={nameColSpan}>
                <BodySmallMedium>Namn</BodySmallMedium>
              </th>
              <th id="role" colSpan={colSpan}></th>
              <th id="createdAt" onClick={() => handleSort('createdAt')}>
                <BodySmallMedium>
                  Tillagd
                  <Spacing>{renderSortIcon('createdAt')}</Spacing>
                </BodySmallMedium>
              </th>
              <th id="lastLogin" onClick={() => handleSort('lastLogin')}>
                <BodySmallMedium>
                  Senast inloggad
                  <Spacing>{renderSortIcon('lastLogin')}</Spacing>
                </BodySmallMedium>
              </th>
              <th id="actions"></th>
            </tr>
            <tr>
              <th colSpan={lineColSpan}>
                <Line />
              </th>
            </tr>
          </StyledTHead>

          <tbody>
            {sortedEmployees.map((employee, index) => {
              const isEmployeeAdmin = employee.UserPermission.some(({ permission }) => permission === 'ADMIN')
              const newPermission = isEmployeeAdmin ? 'EMPLOYEE' : 'ADMIN'
              const permissionMapping = {
                ['ADMIN']: 'admin',
                ['EMPLOYEE']: 'medarbetare',
              }

              return (
                <React.Fragment key={index}>
                  <StyledTr $isMobile={isMobileDevice}>
                    <StyledTd colSpan={nameColSpan} headers="name">
                      <BodySmallMedium>{`${employee.firstName} ${employee.lastName}`}</BodySmallMedium>
                      <BodyTiny>{employee.email}</BodyTiny>
                    </StyledTd>
                    <StyledTd colSpan={colSpan} headers="role">
                      <RoleContainer $role="Inbjuden">
                        <Label>Inbjuden</Label>
                      </RoleContainer>
                    </StyledTd>
                    <StyledTd headers="createdAt">
                      <BodySmallMedium>
                        {format(new Date(employee.createdAt), 'd MMM yyyy', { locale: sv })}
                      </BodySmallMedium>
                    </StyledTd>
                    <StyledTd headers="lastLogin">
                      <BodySmallMedium>
                        {employee.lastLogin ? format(new Date(employee.lastLogin), 'd MMM yyyy', { locale: sv }) : ''}
                      </BodySmallMedium>
                    </StyledTd>
                    <StyledTd headers="actions">
                      <MenuDropdown
                        menuItems={[
                          {
                            label: 'Redigera',
                            onClick: () => setSelectedEmployee(employee),
                          },
                          {
                            label: 'Ta bort användare',
                            onClick: () => {
                              setSelectedEmployee(employee)
                              setOpenDialog(true)
                            },
                            disabled: employee.id === userId,
                          },
                          {
                            label: isEmployeeAdmin ? 'Ta bort adminbehörighet' : 'Ge adminbehörighet',
                            onClick: () => {
                              updateUserMutation(
                                { id: employee.id, newUserData: { permission: newPermission } },
                                {
                                  onSuccess: () => {
                                    refetchGetUsersFromOrganisationQuery()
                                    setAlertState({
                                      message: `Användaren har fått ${permissionMapping[newPermission]} behörighet.`,
                                      type: 'success',
                                    })
                                  },
                                  onError: (error) => {
                                    setAlertState({
                                      message: error.message || 'Det gick inte att uppdatera användaren.',
                                      type: 'error',
                                    })
                                  },
                                }
                              )
                            },
                            disabled: employee.id === userId,
                          },
                        ]}
                      >
                        <HorizontalDots size={20} />
                      </MenuDropdown>
                    </StyledTd>
                  </StyledTr>
                  {index < sortedEmployees.length - 1 && (
                    <tr>
                      <td colSpan={lineColSpan}>
                        <Line />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              )
            })}
          </tbody>
        </StyledTable>
      </ComponentLayout>

      {openDialog && (
        <Modal onClose={() => setOpenDialog(false)}>
          <ModalFlexWrapper>
            <div>
              <StyledTitle4 style={{ marginBottom: '5px' }}>Radera användare</StyledTitle4>
              <BodyLarge>
                Användare
                <b>{` ${selectedEmployee?.firstName} ${selectedEmployee?.lastName} `}</b>
                kommer att raderas permanent. Är du säker på att du vill fortsätta?
              </BodyLarge>
            </div>

            <OnClickButton secondary text="Fortsätt" onClick={onDeleteUser} />
          </ModalFlexWrapper>
        </Modal>
      )}
      {showDropZone && (
        <Modal
          onClose={() => {
            setShowDropZone(false)
            setFileUploadState(null)
          }}
          backgroundColor={theme.color.beigeLight}
        >
          <ModalTitle>Lägg till flera medarbetare</ModalTitle>
          <BodySmall>Lägg till flera medarbetare samtidigt genom att lista dem i en CSV-fil.</BodySmall>
          <StyledBodySmall>Använd vår mall för att formatera filen på ett korrekt sätt.</StyledBodySmall>
          <DropZone
            acceptedFiles={{ 'text/csv': ['.csv'] }}
            onDrop={(files) => uploadFile(files)}
            fileUploadState={fileUploadState}
            errorMessage={errorMessage}
          />
        </Modal>
      )}
    </>
  )
}

const StyledTitle2 = styled(Title2)`
  margin-bottom: ${theme.spacing.xsmall}px;
`
const Spacing = styled.span`
  margin-left: ${theme.spacing.xsmall}px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`
const SearchContainer = styled.div`
  background-color: ${theme.color.white};
  border-radius: 50px;
  box-shadow: 0px 0px 2px 0.5px rgba(0, 0, 0, 0.15);
  border: none;
  width: 330px;
  height: ${theme.spacing.xxlarge}px;
  display: flex;
  align-items: center;
  padding: 0 ${theme.spacing.small}px;
  &:focus-within {
    border: 1px solid ${theme.color.plumMid};
  }
  @media screen and (max-width: ${theme.breakpoint.xsmall}px) {
    width: 100%;
  }
`

const SearchInput = styled.input`
  font-size: ${theme.fontSize.small}px;
  font-family: ${theme.font.body};
  color: ${theme.color.grey4};
  height: 90%;
  border-radius: 50px;
  border: none !important;
  &:focus {
    border: none !important;
    color: ${theme.color.grey5};
  }
`
const InputContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ $isMobile }) => ($isMobile ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ $isMobile }) => ($isMobile ? theme.spacing.large : theme.spacing.mediumLarge)}px;
  button {
    margin-top: ${({ $isMobile }) => ($isMobile ? theme.spacing.medium : 0)}px;
    @media screen and (max-width: 450px) {
      width: 100%;
    }
    width: ${({ $isMobile }) => ($isMobile ? '330px' : '200px')};
  }
`

const StyledTr = styled.tr<{ $isMobile: boolean }>`
  td {
    &[headers='role'],
    &[headers='createdAt'],
    &[headers='lastLogin'] {
      display: ${({ $isMobile }) => ($isMobile ? 'none' : '')};
    }
  }
`

const RoleContainer = styled.div<{ $role: string | null }>`
  display: ${({ $role }) => ($role ? 'inline-block' : 'none')};
  color: ${({ $role }) => ($role === 'Inbjuden' ? theme.color.black : theme.color.white)};
  padding: 6px ${theme.spacing.xsmall}px;
  margin-left: ${theme.spacing.small}px;
  border-radius: ${theme.spacing.tiny}px;
  background-color: ${({ $role }) => ($role === 'Inbjuden' ? theme.color.yellowLight : theme.color.brightBlue)};
`
const Line = styled.div`
  border-bottom: 1px solid ${theme.color.beigeDark};
  width: 100%;
`
const StyledTable = styled.table`
  width: 100%;
  border-radius: ${theme.spacing.medium}px;
  padding: ${theme.spacing.medium}px;
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.beigeDark};
  table-layout: fixed;

  th,
  td {
    width: 20%;
    &:last-child {
      width: 10%;
    }
    &:first-child {
      width: 30%;
      @media screen and (max-width: ${theme.breakpoint.large}px) {
        width: 90%;
      }
    }
  }
`
const StyledTHead = styled.thead<{ $isMobile: boolean }>`
  text-align: left;
  padding: ${theme.spacing.medium}px 0;
  border-bottom: 3px solid ${theme.color.grey2};
  #role,
  #createdAt,
  #lastLogin,
  #actions {
    display: ${({ $isMobile }) => ($isMobile ? 'none' : '')};
  }
  p {
    margin-bottom: ${theme.spacing.medium}px;
  }
`

const StyledTd = styled.td`
  padding: ${theme.spacing.medium}px 0;
  text-align: left;
  display: table-cell;
  vertical-align: middle;
  word-wrap: break-word;

  &[headers='actions'] {
    text-align: right;
    padding-right: ${theme.spacing.tiny}px;
  }
  &[headers='role'],
  &[headers='createdAt'],
  &[headers='lastLogin'] {
    @media screen and (max-width: ${theme.breakpoint.medium}px) {
      display: none;
    }
  }
`
const ModalFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 250px;
  gap: ${theme.spacing.medium}px;
`

const StyledTitle4 = styled(Title4)`
  margin-bottom: ${theme.spacing.xsmall}px;
`
const AlertWrapper = styled.div`
  margin-top: ${theme.spacing.large}px;
  position: relative;
  height: 46px;
  overflow: hidden;
  margin-bottom: ${theme.spacing.mediumLarge}px;
`

const ModalTitle = styled(Title4)`
  margin-bottom: ${theme.spacing.medium}px;
`

const StyledBodySmall = styled(BodySmall)`
  margin-bottom: ${theme.spacing.mediumLarge}px;
`

const IconWrapper = styled.div<{ $rotation: number }>`
  display: inline-block;
  transform: rotate(${(props) => props.$rotation}deg);
  transition: transform 0.2s ease-in-out;
`
