import { useGetEntriesByContentType, useGetGeneralPage } from '../../api/contentful/contentfulQueries'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { BodyLarge, Title2 } from '../../components/styles/Typography'
import { PreviewGuard } from '../../components/PreviewGuard'
import { IArticleFields, IHelpAreaFields, IPodcastEpisodeFields, isICollection } from '@estercare/ester-shared'
import { Collection } from '../../components/Collection'
import { theme } from '../../theme/theme'
import { PodcastList } from '../../components/PodcastList'
import { ArticleList } from '../../components/ArticleList'
import styled from 'styled-components'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { OnClickButton } from '../../components/buttons/onclick/OnClickButton'
import { Entry } from 'contentful'
import { useState } from 'react'
import { filterArchiveByRelatedArea } from '../../utils/archive/archive'
import { ResultList } from '../../components/ResultList'

export const Archive = () => {
  const archiveQuery = useGetGeneralPage('archive')
  const articleEntries = useGetEntriesByContentType<IArticleFields>('article').data?.items
  const podcastEpisodesEntries = useGetEntriesByContentType<IPodcastEpisodeFields>('podcastEpisode').data?.items
  const relatedAreas = useGetEntriesByContentType<IHelpAreaFields>('helpArea').data?.items

  const archiveContent = archiveQuery?.data?.items?.[0]?.fields
  const allArticlesNoMini = articleEntries && articleEntries.filter((article) => !article.fields.isMiniArticle)
  const articles = allArticlesNoMini?.map((entry) => entry.fields)
  const podcastEpisodes = podcastEpisodesEntries?.map((entry) => entry.fields)

  const [filteredArticles, setFilteredArticles] = useState<IArticleFields[] | null>(null)
  const [filteredPodcasts, setFilteredPodcasts] = useState<IPodcastEpisodeFields[] | null>(null)
  const [activeButton, setActiveButton] = useState<number | null>(null)

  if (archiveQuery.isLoading) return <LoadingSpinner />
  if (!archiveContent) {
    return <PreviewGuard />
  }

  const { description, contentBlocks } = archiveContent

  const resetFilter = () => {
    setFilteredArticles(null)
    setFilteredPodcasts(null)
  }

  const applyFilter = (area: Entry<IHelpAreaFields>) => {
    const { relatedPodcasts, relatedArticles } = filterArchiveByRelatedArea(area, articles, podcastEpisodes)
    relatedArticles && setFilteredArticles(relatedArticles)
    relatedPodcasts && setFilteredPodcasts(relatedPodcasts)
  }

  const toggleFilter = (area: Entry<IHelpAreaFields>, buttonIndex: number) => {
    if (activeButton === buttonIndex) {
      resetFilter()
      setActiveButton(null)
    } else {
      applyFilter(area)
      setActiveButton(buttonIndex)
    }
  }

  const collections =
    contentBlocks &&
    contentBlocks.map((block, index: number) => {
      if (isICollection(block)) {
        const color = index % 2 === 0 ? theme.color.beigeDark : ''
        const horizontal = index % 2 === 0

        return (
          <ComponentLayout key={index} backgroundColor={color}>
            <Collection key={index} collection={block} horizontal={horizontal} />
          </ComponentLayout>
        )
      } else return null
    })

  return (
    <>
      <ComponentLayout paddingBottom={0}>
        <StyledTitle2>Våra artiklar och poddavsnitt</StyledTitle2>
        <StyledBodyLarge>{description}</StyledBodyLarge>
      </ComponentLayout>
      <ComponentLayout paddingBottom={activeButton ? 0 : theme.spacing.xlarge}>
        {relatedAreas?.map((area, index) => (
          <HelpArea key={index}>
            {area.fields.name && (
              <OnClickButton
                key={`${index}-button`}
                onClick={() => toggleFilter(area, index)}
                text={area.fields.name}
                secondary={activeButton === index ? false : true}
                buttonColor={theme.color.plumMid}
                buttonStyle={{
                  padding: '8px 16px',
                  minWidth: 'max-content',
                  width: 'fit-content',
                  margin: '4px',
                  fontSize: `${theme.fontSize.small}px`,
                }}
              />
            )}
          </HelpArea>
        ))}
      </ComponentLayout>
      {(!filteredArticles || !filteredPodcasts) && collections}
      {filteredArticles && (
        <ComponentLayout>
          <ResultList title={`Artiklar (${filteredArticles.length})`} items={filteredArticles} />
        </ComponentLayout>
      )}
      {filteredPodcasts && (
        <ComponentLayout>
          <ResultList title={`Poddavsnitt (${filteredPodcasts.length})`} items={filteredPodcasts} />
        </ComponentLayout>
      )}
      {!filteredArticles && !filteredPodcasts && (
        <>
          <ComponentLayout
            backgroundColor={filteredPodcasts ? theme.color.beigeLight : theme.color.beigeDark}
            paddingBottom={activeButton ? theme.spacing.tiny : theme.spacing.xlarge}
          >
            {!podcastEpisodes ? (
              <PreviewGuard />
            ) : (
              <PodcastList podcasts={podcastEpisodes} title="Alla poddavsnitt" showAsArchive />
            )}
          </ComponentLayout>
          <ComponentLayout>
            {!articles ? <PreviewGuard /> : <ArticleList articles={articles} title="Alla artiklar" showAsArchive />}
          </ComponentLayout>
        </>
      )}
    </>
  )
}

const HelpArea = styled.div`
  display: inline-block;
`

const StyledTitle2 = styled(Title2)`
  margin-bottom: ${theme.spacing.xsmall}px;
`

const StyledBodyLarge = styled(BodyLarge)`
  max-width: 640px;
`
