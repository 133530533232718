import styled from 'styled-components'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { BodyLarge, BodySmall, Title2 } from '../../components/styles/Typography'
import { Arrow, InvoiceBlank, User } from '../../components/icons'
import { theme } from '../../theme/theme'
import { StackedBarChart } from '../../components/charts/StackedBarChart'
import { chartData, chartData1, chartData2 } from '../../components/charts/mockData'
import { FAQ } from '../../components/Faq'
import { useGetAllServicesPages, useGetEntryById } from '../../api/contentful/contentfulQueries'
import { isIFAQ } from '@estercare/ester-shared'
import { Link } from 'react-router-dom'
import * as IconComponents from '../../components/icons'
import React from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { ServiceCard } from '../../components/ServiceCard'

import { useSelectActiveServicesFromContentful } from '../../state/selectors'

const getStartedCardArr = [
  {
    icon: <User size={25} color={theme.color.white} />,
    largeText: 'Hantera medarbetare',
    smallText: 'Lägg till, ta bort och hantera medarbetare i ditt företag.',
  },
  {
    icon: <InvoiceBlank size={21} color={theme.color.white} />,
    largeText: 'Skapa policy',
    smallText: 'Ta fram en policy för kvinnohälsa på er arbetsplats.',
  },
]

export const HomeAdmin = () => {
  const faq = useGetEntryById('ojxTHwOE2LlV22jFQ6n1B').data
  const { data, isLoading: isLoadingServices } = useGetAllServicesPages()
  const allServices = data?.items || []
  const activeServices = useSelectActiveServicesFromContentful(allServices)

  return (
    <ComponentLayout>
      <StyledTitle2>Välkommen till EsterCare för företag!</StyledTitle2>
      <Wrapper $marginTop={32}>
        <StyledBodyLarge>Tips för att komma igång</StyledBodyLarge>
        <FlexWrapper>
          {getStartedCardArr.map(({ largeText, icon, smallText }, index) => (
            <div key={index}>
              <GetStartedCard key={index}>
                <GetStartedIconWrapper>{icon}</GetStartedIconWrapper>
                <StyledBodyLarge>{largeText}</StyledBodyLarge>
                <StyledBodySmall>{smallText}</StyledBodySmall>
              </GetStartedCard>
            </div>
          ))}
        </FlexWrapper>
      </Wrapper>
      {activeServices.length > 0 && (
        <Wrapper>
          <StyledBodyLarge>Lägg till fler tjänster</StyledBodyLarge>
          <FlexWrapper>
            {isLoadingServices ? (
              <LoadingSpinner />
            ) : (
              activeServices.map(({ fields: { title, shortDescription, slug, iconType }, sys: { id } }) => (
                <ServiceCard
                  key={id}
                  title={title!}
                  description={shortDescription || ''}
                  linkTo={`tjanster/${slug}`}
                  icon={
                    iconType &&
                    React.createElement(IconComponents[iconType as keyof typeof IconComponents], {
                      size: 22,
                      color: theme.color.plumMid,
                    })
                  }
                />
              ))
            )}
          </FlexWrapper>
        </Wrapper>
      )}

      <Wrapper>
        <StyledBodyLarge>Deltagande</StyledBodyLarge>

        <StyledLink to="/admin/statistik">
          <p>Se all statistik</p>
          <Arrow size={16} />
        </StyledLink>

        <StyledChartWrapper>
          <StackedBarChart data={chartData} title="Aktivering av konton" />
        </StyledChartWrapper>
        <StyledChartWrapper>
          <StackedBarChart data={chartData1} title="Deltagande i utbildning" />
        </StyledChartWrapper>
        <StyledChartWrapper>
          <StackedBarChart data={chartData2} title="Deltagande i screeningtjänst för kvinnohälsa" />
        </StyledChartWrapper>
      </Wrapper>

      <Wrapper $marginTop={32}>
        {isIFAQ(faq) && (
          <FAQ
            showSupportLink
            title={faq.fields.title}
            faqItems={faq.fields.faqItems}
            imageAlignment
            backgroundColor="beigeLight"
          />
        )}
      </Wrapper>
    </ComponentLayout>
  )
}

const StyledTitle2 = styled(Title2)`
  && {
    max-width: 400px;
  }
`
const Wrapper = styled.div<{ $marginTop?: number }>`
  margin-top: ${({ $marginTop }) => $marginTop ?? theme.spacing.xxlarge}px;
  position: relative;
`

const StyledBodyLarge = styled(BodyLarge)`
  font-weight: 500;
`
const StyledBodySmall = styled(BodySmall)`
  margin-top: ${theme.spacing.xsmall}px;
`

const GetStartedCard = styled.div`
  color: ${theme.color.white};
  width: 100%;
  max-width: 536px;
  background-color: ${theme.color.plumMid};
  padding: ${theme.spacing.large}px;
  border-radius: 16px;
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 3px 9px 0px rgba(0, 0, 0, 0.05);
`
const FlexWrapper = styled.div`
  margin-top: ${theme.spacing.medium}px;
  display: flex;
  gap: ${theme.spacing.mediumLarge}px;
  overflow: auto;
  @media screen and (max-width: ${theme.breakpoint.large}px) {
    flex-direction: column;
  }
`
const GetStartedIconWrapper = styled.div`
  padding: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${theme.color.white}15;
  margin-bottom: ${theme.spacing.medium}px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledChartWrapper = styled.div`
  margin-top: ${theme.spacing.medium}px;
`
const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  color: ${theme.color.plumMid};
  display: flex;
  align-items: center;
  gap: 3px;
  p {
    font-weight: 500;
    font-family: ${theme.font.body};
    font-size: 14.8px;
    line-height: 22px;
    letter-spacing: 0.1px;
  }
`
