import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { Title1 } from '../../components/styles/Typography'

export const Support = () => {
  return (
    <ComponentLayout>
      <Title1>Support</Title1>
    </ComponentLayout>
  )
}
