import * as Sentry from '@sentry/react'
import { appConfig } from '../config'

export const sendMessageToSentry = (message: string) => {
  // If sentry is disabled, log locally
  if (!sentryIsActive) {
    // eslint-disable-next-line no-console
    console.info(message)
  }
  Sentry.captureMessage(message, 'info')
}

export const sendErrorToSentry = (error: unknown) => {
  // If sentry is disabled, log locally
  if (!sentryIsActive) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
  Sentry.captureException(error)
}

export const sentryIsActive = Boolean(appConfig.sentry.sentryDsn)
