import { FC, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { Home } from '../../views/employee/Home'
import { ViewLayout } from '../layouts/ViewLayout'
import { useSelectIsAdmin } from '../../state/selectors'
import { useServicesStore } from '../../state/store'
import { useGetActiveServicesQuery } from '../../api/ester-b2b-api/private/services/serviceQueries'

export const AdminViewGuard: FC = () => {
  const { setActiveServices } = useServicesStore()
  const isAdmin = useSelectIsAdmin()
  const { data: servicesData } = useGetActiveServicesQuery()

  useEffect(() => {
    if (isAdmin && servicesData && servicesData.length > 0) {
      setActiveServices(servicesData)
    }
  }, [isAdmin, servicesData, setActiveServices])

  return <ViewLayout>{isAdmin ? <Outlet /> : <Home />}</ViewLayout>
}

export const EmployeeViewGuard: FC = () => {
  return (
    <ViewLayout>
      <Outlet />
    </ViewLayout>
  )
}
