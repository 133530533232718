import * as Sentry from '@sentry/react'
import { appConfig } from './config'

export const initSentry = () => {
  const DSN = appConfig.sentry.sentryDsn
  // eslint-disable-next-line no-console
  if (!DSN) console.log('No Sentry DSN found, skipping initialization')

  Sentry.init({
    dsn: DSN,
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (
        event.exception &&
        event.event_id &&
        event.exception.values &&
        event.exception.values[0]?.mechanism &&
        event.exception.values[0].mechanism.handled === false
      ) {
        Sentry.showReportDialog({ eventId: event.event_id })
      }
      return event
    },
    integrations: [new Sentry.BrowserTracing({}), new Sentry.Replay()],
    environment: appConfig.environment,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
