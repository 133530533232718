import { Entry } from 'contentful'
import { useServicesStore, useUserStore } from './store'
import { IPortalGeneralPageFields, Services } from '@estercare/ester-shared'
import { isServiceIdentifier } from '../utils/typeGuards'

export const useSelectIsAdmin = () => useUserStore((state) => state.user?.permissions.includes('ADMIN'))
export const useSelectUserId = () => useUserStore((state) => state.user?.id)
export const useSelectUserFullName = () => useUserStore((state) => `${state.user?.firstName} ${state.user?.lastName}`)
export const useSelectFirstName = () => useUserStore((state) => state.user?.firstName)
export const useSelectLastName = () => useUserStore((state) => state.user?.lastName)
export const useSelectUserEmail = () => useUserStore((state) => state.user?.email)
export const useSelectUserOrganisationId = () => useUserStore((state) => state.user?.organisationId)
export const useSelectUserOrganisationName = () => useUserStore((state) => state.user?.organisationName)

export const useSelectActiveServices = () => useServicesStore((state) => state.activeServices)

export const useSelectActiveServicesFromContentful = (allServices: Entry<IPortalGeneralPageFields>[]) => {
  return useServicesStore((state) => {
    const { activeServices } = state

    return allServices.filter((service) => {
      const { serviceIdentifier } = service.fields

      if (!isServiceIdentifier(serviceIdentifier)) {
        return false
      }

      return activeServices.includes(serviceIdentifier as Services)
    })
  })
}
