import { appConfig } from '../../../../config'
import { AccessCode, CreateUsersResponse, User, UsersFromOrganisationDb } from './types'

export const getUsers = async ({ accessToken }: { accessToken: string }): Promise<User[]> => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/users`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
  const data = await res.json()
  return data
}

export const getUser = async ({ accessToken, id }: { accessToken: string; id: string }) => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/users/${id}`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
  const data = await res.json()
  return data
}

export const deleteUser = async ({ accessToken, id }: { accessToken: string; id: string }) => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/users/${id}`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
  const data = await res.json()
  return data
}

export const getUsersFromOrganisation = async ({
  accessToken,
  organisationId,
}: {
  accessToken: string
  organisationId: string
}): Promise<UsersFromOrganisationDb[]> => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/users/?organisation=${organisationId}`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
  const data = await res.json()
  return data
}

export const postUsers = async ({
  accessToken,
  usersFileData,
}: {
  accessToken: string
  usersFileData: FormData
}): Promise<CreateUsersResponse> => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/users`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: usersFileData,
  })

  const data = await res.json()

  if (!res.ok) {
    throw new Error(`Request failed with status ${res.status}. Error: ${JSON.stringify(data)}`)
  }

  return data
}

export const logIn = async ({ accessToken }: { accessToken: string }): Promise<User> => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/login`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })

  const data = await res.json()

  if (!res.ok) {
    throw new Error(data?.message || 'Unknown error')
  }

  return data
}
export const getUserAccessCode = async ({
  accessToken,
  id,
}: {
  accessToken: string
  id: string
}): Promise<AccessCode> => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/users/${id}/accesscode`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
  const data = await res.json()
  return data
}

export const updateUser = async ({
  accessToken,
  id,
  newUserData,
}: {
  accessToken: string
  id: string
  newUserData: Partial<User>
}): Promise<AccessCode> => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/users/${id}`, {
    method: 'PATCH',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(newUserData),
  })
  const data = await res.json()

  if (!res.ok) {
    throw new Error(data?.message || 'Unknown error')
  }

  return data
}
