import styled from 'styled-components'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { BodyLarge, Title2, Title4 } from '../../components/styles/Typography'
import { theme } from '../../theme/theme'
import { useGetGeneralPage } from '../../api/contentful/contentfulQueries'
import { PreviewGuard } from '../../components/PreviewGuard'
import { isILinkList } from '@estercare/ester-shared'
import { SupportBlock } from '../../components/SupportBlock'

export const EmployeeSupport = () => {
  const supportQuery = useGetGeneralPage('support-anstaelld')
  const supportContent = supportQuery?.data?.items?.[0]?.fields
  if (!supportContent) {
    return <PreviewGuard />
  }
  const { description, contentBlocks } = supportContent

  return (
    <>
      <ComponentLayout>
        <StyledTitle2>Hur kan vi hjälpa till?</StyledTitle2>
        <StyledBodyLarge>{description}</StyledBodyLarge>
      </ComponentLayout>
      <ComponentLayout paddingTop={0}>
        {contentBlocks &&
          contentBlocks.map((block, index) => {
            return isILinkList(block) && <SupportBlock key={index} linkList={block.fields} />
          })}
      </ComponentLayout>
      <ComponentLayout paddingTop={0}>
        <OtherQuestions>
          <Title4>Letar du efter något annat?</Title4>
          <StyledBodyLarge>
            Maila till <a href="mailto:support@estercare.com">support@estercare.com</a> så hjälper vi dig där!
          </StyledBodyLarge>
        </OtherQuestions>
      </ComponentLayout>
    </>
  )
}
const OtherQuestions = styled.div`
  background-color: ${theme.color.beigeDark};
  padding: ${theme.spacing.xlarge}px;
  border-radius: ${theme.spacing.medium}px;
`

const StyledTitle2 = styled(Title2)`
  margin-bottom: ${theme.spacing.xsmall}px;
`

const StyledBodyLarge = styled(BodyLarge)`
  max-width: 640px;
  a {
    color: ${theme.color.plumMid};
    font-weight: 500;
  }
`
