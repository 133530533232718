import styled from 'styled-components'
import { BodyLarge, Title4 } from '../../components/styles/Typography'
import { theme } from '../../theme/theme'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { Title1 } from '../../components/styles/Typography'

export const Invoices = () => {
  return (
    <>
      <ComponentLayout>
        <Title1>Fakturering</Title1>
      </ComponentLayout>
      <ComponentLayout paddingTop={0}>
        <TempBanner>
          <Title4>Stöd för att kunna se dina fakturor kommer snart!</Title4>
          <StyledBodyLarge>
            Under tiden kan du maila till <a href="mailto:support@estercare.com">support@estercare.com</a> så hjälper vi
            dig där!
          </StyledBodyLarge>
        </TempBanner>
      </ComponentLayout>
    </>
  )
}

const TempBanner = styled.div`
  background-color: ${theme.color.beigeDark};
  padding: ${theme.spacing.xlarge}px;
  border-radius: ${theme.spacing.medium}px;
`

const StyledBodyLarge = styled(BodyLarge)`
  max-width: 640px;
  a {
    color: ${theme.color.plumMid};
    font-weight: 500;
  }
`
