import { ICollection } from '@estercare/ester-shared'
import { FC } from 'react'
import styled from 'styled-components'
import { Title6 } from './styles/Typography'
import { PreviewGuard } from './PreviewGuard'
import { BlockItem } from './BlockItem'
import { Grid } from './Grid'
import { theme } from '../theme/theme'
import { CollectionItem } from './CollectionItem'

interface Props {
  collection: ICollection
  horizontal?: boolean
}

export const Collection: FC<Props> = ({ collection, horizontal = true }) => {
  const { title, items } = collection.fields
  if (!items) return <PreviewGuard />
  const height = horizontal ? 215 : 368
  const columns = horizontal ? 2 : 4
  const collectionItems = items.map((item, index) => {
    return (
      <BlockItem key={index} height={height}>
        <CollectionItem item={item} />
      </BlockItem>
    )
  })
  return (
    <CollectionContainer>
      <Title6>{title}</Title6>
      <StyledGrid $columns={columns}>{collectionItems}</StyledGrid>
    </CollectionContainer>
  )
}

const StyledGrid = styled(Grid)`
  margin-top: ${theme.spacing.medium}px;
`

const CollectionContainer = styled.div``
