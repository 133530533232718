import styled from 'styled-components'
import { FC, ReactNode } from 'react'
import { theme } from '../theme/theme'
import { Label, Title6, BodySmall } from './styles/Typography'
import { LinkButton } from './buttons/link/LinkButton'

interface Props {
  isActive?: boolean
  title: string
  description: string
  linkTo: string
  icon?: ReactNode
}

export const ServiceCard: FC<Props> = ({ isActive, title, description, linkTo, icon }) => {
  return (
    <Card>
      <CardHeader>
        <CardIconLabelContainer>
          {icon && <CardIcon>{icon}</CardIcon>}
          {isActive && <CardLabel>AKTIV</CardLabel>}
        </CardIconLabelContainer>
      </CardHeader>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
      <StyledButtonWrapper>
        <LinkButton href={linkTo} secondary text="Läs mer" />
      </StyledButtonWrapper>
    </Card>
  )
}

const Card = styled.div`
  width: 100%;
  border: 1px solid ${theme.color.beigeDark};
  border-radius: ${theme.spacing.medium}px;
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 3px 9px 0px rgba(0, 0, 0, 0.05);
  background: ${theme.color.white};
  padding: ${theme.spacing.xlarge}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-width: 296px;
`

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.spacing.medium}px;
`

const CardIconLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const CardIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${theme.color.plumlight};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CardLabel = styled(Label)`
  background-color: ${theme.color.green};
  color: ${theme.color.white};
  padding: 6px 8px;
  border-radius: 4px;
  text-transform: uppercase;
  margin-left: auto;
`

const CardTitle = styled(Title6)`
  margin-bottom: ${theme.spacing.xsmall}px;
  font-weight: 500;
  word-wrap: break-word;
`

const CardDescription = styled(BodySmall)`
  color: ${theme.color.grey4};
  margin-bottom: ${theme.spacing.mediumLarge}px;
  height: 42px;
`

const StyledButtonWrapper = styled.div`
  margin-top: ${theme.spacing.mediumLarge}px;
  @media screen and (min-width: ${theme.breakpoint.medium}px) {
    max-width: 150px;
  }
  @media screen and (max-width: ${theme.breakpoint.small}px) {
    * {
      width: 100%;
    }
  }
`
