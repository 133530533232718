import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { BodyLarge, Title2 } from '../../components/styles/Typography'
import { OnClickButton } from '../../components/buttons/onclick/OnClickButton'

const handleDownload = () => {
  const docUrl = 'https://ester-public-assets.s3.eu-north-1.amazonaws.com/policy-template-240516.docx'
  window.open(docUrl, '_blank')
}

export const PolicyAdmin = () => {
  return (
    <ComponentLayout>
      <StyledTitle2>Policy för kvinnohälsa</StyledTitle2>
      <StyledBodyLarge>
        Här erbjuds en mall som kan användas som utgångspunkt för att ta fram en policy för er arbetsplats. När ni
        färdigställt den kan ni ladda upp er version här för att göra den synlig för era anställda när de loggar in i
        denna tjänst.
      </StyledBodyLarge>
      <OnClickButton text="Ladda ned" onClick={handleDownload} />
    </ComponentLayout>
  )
}
const StyledTitle2 = styled(Title2)`
  margin-bottom: ${theme.spacing.xlarge}px;
`
const StyledBodyLarge = styled(BodyLarge)`
  margin-bottom: ${theme.spacing.medium}px;
  max-width: 640px;
`
